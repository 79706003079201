
import jwt from "../../http/requests/auth/jwt/index.js"

export default {

  getDirectorAboutUs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorAboutUsList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLearningCentreListDirector({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getLearningCentreDirector()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createAboutUsDirector({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveAboutUsDirector(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAboutUsDetailsDirector({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getAboutUsDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageDirector({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .ImageUpload(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAboutUsDirector({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateAboutUs(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /** super admin **/
  getAdminAboutUs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getAdminAboutUsList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLearningCentreListAdmin({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getLearningCentreAdmin()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createAboutUsAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveAboutUsAdmin(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAboutUsDetailsAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getAdminAboutUsDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .ImageUploadAdmin(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAboutUsAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateAdminAboutUs(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
    For Parent
  */
  fetchParentAboutUsByCenterId({ commit }, learningCenterId) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentAboutUsByCenterId(learningCenterId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
