/*=========================================================================================
  File Name: moduleActivityGetter.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Activity Getter
  Author: Samir Karmacharya
  Author URL:
==========================================================================================*/


export default {}
