/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    SET_CORE_SKILLS(state, coreSkills) {
        state.coreSkills = coreSkills
    },
    ADD_CORE_SKILL(state, data) {
        state.coreSkills.unshift(data)
    },
    UPDATE_CORE_SKILL(state, data) {
        const coreSkillIndex = state.coreSkills.findIndex((u) => u._id == data._id)
        Object.assign(state.coreSkills[coreSkillIndex], data)
    },
    DELETE_CORE_SKILL(state, itemId) {
        const coreSkillIndex = state.coreSkills.findIndex((u) => u._id == itemId)
        state.coreSkills.splice(coreSkillIndex, 1)
    },



    /*developments*/
    SET_RATING(state, rating) {
        state.rating = rating;
    },
    SET_CORE_SKILL_OPTIONS(state, coreSkillsOptions) {
        state.coreSkillsOptions = coreSkillsOptions
    },
    SET_CORE_SKILL_FILTER(state, coreSkillsFilter) {
        state.coreSkillFilter = coreSkillsFilter
    },
    SET_CORE_SKILL_DEVELOPMENT(state, coreSkillDevelopments) {
        state.coreSkillDevelopments = coreSkillDevelopments
    },
    ADD_CORE_SKILL_DEVELOPMENT(state, data) {
        if(state.rating == data.rating){
            state.coreSkillDevelopments.unshift(data)
        }
    },
    DELETE_CORE_SKILL_DEVELOPMENT(state, itemId) {
        const coreSkillDevelopmentIndex = state.coreSkillDevelopments.findIndex((u) => u._id == itemId)
        state.coreSkillDevelopments.splice(coreSkillDevelopmentIndex, 1)
    },
    UPDATE_CORE_SKILL_DEVELOPMENT(state, data) {
        const resource = {
            description: data.description,
            link: data.link,
            title: data.name,
            _id: data.resourceId
        }

        const coreSkillDevelopmentIndex = state.coreSkillDevelopments.findIndex((u) => u._id == data.resourceId)

        if(state.rating == data.rating){
            Object.assign(state.coreSkillDevelopments[coreSkillDevelopmentIndex], resource)
        }else{
            state.coreSkillDevelopments.splice(coreSkillDevelopmentIndex, 1)
        }

    },

}
