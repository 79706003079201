
import jwt from "../../http/requests/auth/jwt/index.js"

export default {

  getDirectorPhilosophies({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorPhilosophiesList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLearningCentreListDirector({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getLearningCentreDirector()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createPhilosophiesDirector({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .savePhilosophiesDirector(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPhilosophiesDetailsDirector({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getPhilosophiesDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageDirector({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .ImageUpload(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePhilosophiesDirector({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updatePhilosophies(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /** super admin **/
  getAdminPhilosophies({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getAdminPhilosophiesList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLearningCentreListAdmin({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getLearningCentreAdmin()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createPhilosophiesAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .savePhilosophiesAdmin(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPhilosophiesDetailsAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getAdminPhilosophiesDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .ImageUploadAdmin(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePhilosophiesAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updatePhilosophiesAdmin(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getPhilosophiesByCenter({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getPhilosophiesByCenter(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
