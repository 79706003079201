/*=========================================================================================
  File Name: moduleActivityActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Actions
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  /*get children */
  async fetchChildrenByRoomId({commit}, roomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildrenByRoomId(roomId)
        .then(async (response) => {
          let data = response.data.data;
          await commit('SET_CHILDREN', data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async fetchChildrenFilterByRoomId({commit}, roomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildrenFilterByRoomId(roomId)
        .then(async (response) => {
          let data = response.data.data;
          await commit('SET_CHILDREN', data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async setSelectedChildId({commit}, childId) {

    return await commit('SET_SELECTED_CHILD_ID', childId);
  },

  async getChildInfoById({commit}, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildById(childId)
        .then(async (response) => {
          await commit('SET_CHILD', response.data.data[0]);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async getChildListByIds({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .fetchChildListByIds(data.childIds, data.parentId)
        .then(async (response) => {
          localStorage.setItem('parentChildren', JSON.stringify(response.data.data))
          if (response.data.data.length > 0) {

            localStorage.setItem('child', JSON.stringify(response.data.data[0]))
          }

          // await commit("SET_CHILDREN_LIST", response.data.data);

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async storeTagChildren({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .saveTaggedChildren(data)
        .then(async (response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}


