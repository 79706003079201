/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios"
import jwt from "../../http/requests/auth/jwt/index.js";

export default {

    fetchAdminUsers({commit}) {
        return new Promise((resolve, reject) => {
            jwt
                .fetchAdmins()
                .then((response) => {
                    commit('SET_USERS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    resetAdminUserPassword({commit}, userId) {
        return new Promise((resolve, reject) => {
            jwt.resetAdminPassword(userId)
                .then((response) => {
                    //commit('REMOVE_RECORD', userId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    storeAdminUser({commit}, user) {
        return new Promise((resolve, reject) => {
            jwt
                .saveAdmin(user)
                .then((response) => {
                    //commit('ADD_RECORD', user)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    removeAdminUser({commit}, userId) {
        return new Promise((resolve, reject) => {
            jwt
                .removeAdmin(userId)
                .then((response) => {
                    commit('REMOVE_RECORD', userId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchAdminUserById({commit}, userId) {
        return new Promise((resolve, reject) => {
            jwt
                .getAdminById(userId)
                .then((response) => {
                    //commit('SET_USER', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateAdminUser({commit}, user) {
        return new Promise((resolve, reject) => {
            jwt
                .updateAdmin(user)
                .then((response) => {
                    //commit('REMOVE_RECORD', userId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    changePassword({commit}, user) {
        return new Promise((resolve, reject) => {
            jwt
                .changePassword(user)
                .then((response) => {
                    //commit('REMOVE_RECORD', userId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async updateProfile({commit}, data) {
        return new Promise((resolve, reject) => {
            jwt
                .updateProfile(data)
                .then((response) => {

                    let data = response.data.data;

                    let  userInfo =  {
                        _id: data.id,
                        fullName: data.fullName,
                        email: data.email,
                        userType: data.userType,
                        profileImage: data.profileImage,
                        status: "Online"
                    }

                    commit('UPDATE_USER_INFO', userInfo, {root: true})
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }


}
