/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  fetchFastCheckQuestions({commit}, presetId) {
    return new Promise((resolve, reject) => {
      jwt
        .getFastCheckQuestions(presetId)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
    Create Fast Check Question
  */
  storeFastCheckQuestions({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveFastCheckQuestions(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*
    update Fast Check Question
 */
  updateFastCheckQuestions({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editFastCheckQuestions(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /*
  Delete Fast Check Question
*/
  deleteFastCheckQuestionById({commit}, questionId) {
    return new Promise((resolve, reject) => {
      jwt.
      removeFastCheckQuestionById(questionId)
        .then((res) => {
          resolve(res);
        }).catch((err) => {
        reject(err);
      })
    })
  }

}
