/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    SET_USERS(state, users) {
        state.adminUsers = users
    },
    SET_USER(state, user) {
        state.adminUser = user
    },
    REMOVE_RECORD(state, itemId) {
        console.log(state.adminUsers);
        const userIndex = state.adminUsers.findIndex((u) => u._id == itemId)
        state.adminUsers.splice(userIndex, 1)

        console.log(state.adminUsers);
    },

    ADD_RECORD(state, user) {
        state.adminUsers.push(user);
    },
}
