/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/



export default {


    SET_COUNTRY_OPTIONS(state, countryOptions) {
        state.countryOptions = countryOptions
    },
    SET_ROOM_OPTIONS(state, roomOptions) {
        state.roomOptions = roomOptions
    },
    SET_STATES(state, stateDatas) {
        state.stateDatas = stateDatas
    },
    SET_TOTAL_STATES(state, totalDoc) {
        state.totalDoc = totalDoc
    },
    ADD_STATE(state, data){
        console.log(data);
    },
    DELETE_STATE(state, data) {
        const stateIndex = state.stateDatas.findIndex((u) => u.states._id == data.stateId)
        state.stateDatas.splice(stateIndex, 1)
    },





    SET_EYLFS(state, eylfChecklists) {
        state.eylfChecklists = eylfChecklists
    },

    ADD_EYLF(state, data) {
        if(state.eylfType == data.type){
            state.eylfChecklists.unshift(data)
        }
    },
    UPDATE_EYLF(state, data) {
        if(state.eylfType == data.type){
            const eylfChecklistIndex = state.eylfChecklists.findIndex((u) => u._id == data._id)
            Object.assign(state.eylfChecklists[eylfChecklistIndex], data)
        }else{
            const eylfChecklistIndex = state.eylfChecklists.findIndex((u) => u._id == data._id)
            state.eylfChecklists.splice(eylfChecklistIndex, 1)
        }
    },
    DELETE_EYLF(state, itemId) {
        const eylfChecklistIndex = state.eylfChecklists.findIndex((u) => u._id == itemId)
        state.eylfChecklists.splice(eylfChecklistIndex, 1)
    },


    SET_EYLF_TYPE(state, eylfType) {
        state.eylfType = eylfType;
    },



}
