/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)
import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleAdminUser from './admin-user/moduleAdminUserManagement'
import moduleCoreSkill from './core-skill/moduleCoreSkill'
import moduleFastCheck from './fast-check/moduleFastCheck'
import moduleFastCheckQuestion from './fast-check-question/moduleFastCheckQuestion'
import moduleStream from './stream/moduleStream'
import moduleWaysOfLearning from './ways-of-learning/moduleWaysOfLearning'
import moduleEylf from "./early-learning-years-framework/moduleEylf"
import moduleChildrenStories from "./children-stories/moduleChildrenStories"
import moduleActivity from "./activity/moduleActivity";
import moduleSupportEnquiry from "./supportEnquiry/moduleSupportEnquiry";
import moduleGroup from "./group/moduleGroup";
import moduleCenter from "./center/moduleCenter";
import moduleDirector from "./director/moduleDirector";
import modulePage from "./page-management/modulePageManagement";
import moduleUserSubscriptions from "./user-subscriptions/moduleUserSubscriptions";
import moduleSelfAssessment from "./self-assessment/moduleSelfAssessment";
import moduleTeacher from "./teacher/moduleTeacher";
import moduleSetting from "./settings/moduleSettings";
import moduleChild from "./child/moduleChild";
import moduleRoom from "./room/moduleRoom";
import moduleTutorial from "./tutorial/moduleTutorial";
import moduleNewsletter from "./newsletter/moduleNewsletter";
import moduleNotification from "./notification/moduleNotification";
import moduleAgeGroup from "./age-group/moduleAgeGroup";
import moduleCountry from "./country/moduleCountry";
import moduleParent from "./parent/moduleParent";
import moduleCenterAdmin from "./center-admin/moduleCenterAdmin";
import moduleInteraction from "./interaction//moduleInteraction";
import moduleAboutUs from "./about-us/moduleAboutUs";
import modulePhilosophies from "./philosophies/modulePhilosophies";
import moduleEylfCategory from "./eylf-category/moduleEylfCategory";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    adminUser: moduleAdminUser,
    coreSkill: moduleCoreSkill,
    fastCheck: moduleFastCheck,
    fastCheckQuestion: moduleFastCheckQuestion,
    stream: moduleStream,
    waysOfLearning: moduleWaysOfLearning,
    earlyYearLearningFramework: moduleEylf,
    childrenStories: moduleChildrenStories,
    activity: moduleActivity,
    supportEnquiry: moduleSupportEnquiry,
    group: moduleGroup,
    center: moduleCenter,
    director: moduleDirector,
    pages: modulePage,
    userSubscription: moduleUserSubscriptions,
    selfAssessment: moduleSelfAssessment,
    teacher: moduleTeacher,
    email: moduleEmail,
    setting: moduleSetting,
    child: moduleChild,
    room: moduleRoom,
    tutorial: moduleTutorial,
    newsletter: moduleNewsletter,
    notification: moduleNotification,
    ageGroup: moduleAgeGroup,
    country: moduleCountry,
    parent: moduleParent,
    interaction: moduleInteraction,
    centerAdmin: moduleCenterAdmin,
    aboutUs: moduleAboutUs,
    philosophies: modulePhilosophies,
    eylfCategory: moduleEylfCategory
  },
  strict: process.env.NODE_ENV !== 'production'
})
