/*=========================================================================================
  File Name: moduleActivityState.js
  Description: activity state variable defined
  ----------------------------------------------------------------------------------------
  Item Name: Teacher
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {

}
