/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import jwtCA from "../../http/requests/auth/jwt/centerAdmin"
import axios from "../../http/axios/index.js"
import _ from 'lodash';

export default {

  addCoreSkill({commit}, data) {
    let config = {header: {'Content-Type': 'image/png'}}

    return new Promise((resolve, reject) => {

      jwt
        .addCoreSkill(data, config)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data.data;
            delete data.professionalDevelopmentResources;
            commit('ADD_CORE_SKILL', data)
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })


      /*axios.post('admin/save-core-skill', data, config).then(res => {
          const data = res.data.data;
          delete data.professionalDevelopmentResources;
          commit('ADD_CORE_SKILL',data)
          resolve(res)
      }).catch(error => {
          reject(error)
      })*/
    })
  },

  updateCoreSkill({commit}, data) {
    let coreSkillId = data.get("coreSkillId");
    let config = {header: {'Content-Type': 'image/png'}}

    return new Promise((resolve, reject) => {

      jwt
        .updateCoreSkill(coreSkillId, data, config)
        .then((res) => {
          if (res.status === 200) {
            commit('UPDATE_CORE_SKILL', res.data.data)
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })

      /*axios.post('/admin/update-core-skill/' + coreSkillId, data, config).then(res => {
          commit('UPDATE_CORE_SKILL', res.data.data)
          resolve(res)
      }).catch(error => {
          reject(error)
      })*/
    })
  },

  deleteCoreSkill({commit}, coreSkillId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteCoreSkill(coreSkillId)
        .then((response) => {
          if (response.status === 200) {
            commit('DELETE_CORE_SKILL', coreSkillId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })

      /*axios.get('/admin/delete-core-skill/' + coreSkillId).then(response => {
          commit('DELETE_CORE_SKILL', coreSkillId)
          resolve(response)
      }).catch((error) => {
          reject(error)
      })*/
    })
  },

  fetchCoreSkills({commit}) {

    return new Promise((resolve, reject) => {
      jwt
        .fetchCoreSkills()
        .then((res) => {
          if (res.status === 200) {
            commit('SET_CORE_SKILLS', res.data.data)
            let coreSkillsOptions = res.data.data;
            _.forEach(coreSkillsOptions, function (value) {
              value.label = value.name;
              value.value = value._id;
            });

            commit("SET_CORE_SKILL_OPTIONS", coreSkillsOptions);
            commit("SET_CORE_SKILL_FILTER", coreSkillsOptions[0]);
            //this.getFastCheckDevelopment();
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    /*return new Promise((resolve, reject) => {
        axios.get('admin/get-core-skills')
            .then(res => {
                if (res.status === 200) {
                    commit('SET_CORE_SKILLS', res.data.data)
                    let coreSkillsOptions = res.data.data;
                    _.forEach(coreSkillsOptions, function (value) {
                        value.label = value.name;
                        value.value = value._id;
                    });

                    commit("SET_CORE_SKILL_OPTIONS", coreSkillsOptions);
                    commit("SET_CORE_SKILL_FILTER", coreSkillsOptions[0]);
                    //this.getFastCheckDevelopment();
                    resolve(res)
                }
            }).catch((error) => {
            reject(error)
        })
    })*/
  },

  /*Core Skill Developments*/

  setRating({commit}, rating) {
    commit('SET_RATING', rating)
  },

  getCoreSkillDevelopment({commit}, filter) {

    return new Promise((resolve, reject) => {
      jwt
        .getCoreSkillDevelopment(filter)
        .then((res) => {
          let data = res.data.data;
          if (Array.isArray(data)) {
            commit('SET_CORE_SKILL_DEVELOPMENT', []);
          } else {
            commit('SET_CORE_SKILL_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources)
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })


    /*return new Promise((resolve, reject) => {
        axios.post('admin/professional-development/get-core-skills-development', filter)
            .then(res => {
                if (res.status === 200) {
                    let data = res.data.data;
                    if (Array.isArray(data)) {
                        commit('SET_CORE_SKILL_DEVELOPMENT', []);
                    } else {
                        commit('SET_CORE_SKILL_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources)
                    }
                    resolve(res)
                }
            }).catch((error) => {
            reject(error)
        })
    })*/
  },

  deleteCoreSkillDevelopment({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .deleteCoreSkillDevelopment(data)
        .then((response) => {
          commit('DELETE_CORE_SKILL_DEVELOPMENT', data.resourceId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })


    /*return new Promise((resolve, reject) => {
        axios.post('admin/professional-development/delete-core-skills-development/', data)
            .then(res => {
                if (res.status === 200) {
                    commit('DELETE_CORE_SKILL_DEVELOPMENT', data.resourceId)
                    resolve(res)
                }
            }).catch((error) => {
            reject(error)
        })
    })*/
  },

  storeCoreSkillDevelopment({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .storeCoreSkillDevelopment(data)
        .then((response) => {
          let addedData = response.data.data;
          addedData.rating = data.rating;
          commit('ADD_CORE_SKILL_DEVELOPMENT', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
    /*return new Promise((resolve, reject) => {
        axios.post('admin/professional-development/store-core-skills-development', data)
            .then(res => {
                commit('ADD_CORE_SKILL_DEVELOPMENT', res.data.data)
                resolve(res)
            }).catch((error) => {
            reject(error)
        })
    })*/
  },

  updateCoreSkillDevelopment({commit}, data) {


    return new Promise((resolve, reject) => {
      jwt
        .updateCoreSkillDevelopment(data)
        .then((response) => {
          commit('UPDATE_CORE_SKILL_DEVELOPMENT', data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })


    /*return new Promise((resolve, reject) => {
        axios.post('admin/professional-development/update-core-skills-development', data)
            .then(res => {
                commit('UPDATE_CORE_SKILL_DEVELOPMENT', data)
                resolve(res)
            }).catch((error) => {
            reject(error)
        })
    })*/
  },

  logProfessionalDevelopment({commit}, log) {


    return new Promise((resolve, reject) => {
      jwt
        .logProfessionalDevelopment(log)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  logProfessionalDevelopmentResource({commit}, log) {

    return new Promise((resolve, reject) => {
      jwt
        .logProfessionalDevelopmentResource(log)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  logProfessionalDevelopmentForTeachers({commit}, log) {


    return new Promise((resolve, reject) => {
      jwt
        .logProfessionalDevelopmentForTeachers(log)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  fetchParentCoreSkillWithActivityCount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentCoreSkillWithActivityCount(data)
        .then((res) => {
          if (res.status === 200) {
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  },
  fetchCoreSkillWithActivityCount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCoreSkillWithActivityCount(data)
        .then((res) => {
          if (res.status === 200) {
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  fetchCenterAdminCoreSkills({commit}) {

    return new Promise((resolve, reject) => {
      jwtCA
        .fetchCoreSkills()
        .then((res) => {
          if (res.status === 200) {
            commit('SET_CORE_SKILLS', res.data.data)
            let coreSkillsOptions = res.data.data;
            _.forEach(coreSkillsOptions, function (value) {
              value.label = value.name;
              value.value = value._id;
            });

            commit("SET_CORE_SKILL_OPTIONS", coreSkillsOptions);
            commit("SET_CORE_SKILL_FILTER", coreSkillsOptions[0]);
            //this.getFastCheckDevelopment();
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCenterAdminCoreSkillDevelopment({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCoreSkillDevelopment(data)
        .then((res) => {
          let data = res.data.data;
          if (Array.isArray(data)) {
            commit('SET_CORE_SKILL_DEVELOPMENT', []);
          } else {
            commit('SET_CORE_SKILL_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources)
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCoreskillById({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getCoreskillById(id)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }


  }
