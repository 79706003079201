/*=========================================================================================
  File Name: moduleUserSubscriptionsActions.js
  Description:  UserSubscriptions Module Action
  ----------------------------------------------------------------------------------------
  Item Name: User Subscriptions
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
export default {
  /*get User Subscriptions list */
  getUserSubscriptionsList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchUserSubscriptionsList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* get User Subscriptions details */
  getUserSubscriptionsDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchUserSubscriptionsDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* Edit User Subscriptions Details*/
  editUserSubscriptionsDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editUserSubscriptions(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* get Past Subscriptions Details*/
  getPastSubscriptionsDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getPastSubscription(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelSubscriptionByAdmin({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .cancelSubscription(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPackageList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchPackage()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  assignPackage({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .savePackage(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  upgradePackage({ commit }, packageType) {
    return new Promise((resolve, reject) => {
      jwt
        .upgradePackage('', packageType)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addUpdateCancelDateForSubscription({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .addUpdateCancelDateForSubscription(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
    addUpdateCancelDateForSubscriptionByAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .addUpdateCancelDateForSubscriptionByAdmin(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
