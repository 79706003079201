/*=========================================================================================
  File Name: moduleActivityActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Actions
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  /*Update Teacher Profile*/
  async fetchAllTutorials({commit},data) {

    return new Promise((resolve, reject) => {
      jwt
        .fetchTutorials(data)
        .then((response) => {

          let data = response.data.data;

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteTutorial({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeTutorial(id)
        .then((response) => {
          //commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addTutorial({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createTutorial(data)
        .then((response) => {
          //commit('ADD_RECORD', user)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateTutorial({commit}, user) {
    return new Promise((resolve, reject) => {
      jwt
        .updateTutorialDetails(user)
        .then((response) => {
          //commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },



}
