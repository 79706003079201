/*=========================================================================================
  File Name: moduleActivityState.js
  Description: activity state variable defined
  ----------------------------------------------------------------------------------------
  Item Name: Activity
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  activities: [],
  favouriteActivities: [],
  activity: '',
  dateRange: ''
}
