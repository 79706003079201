/*=========================================================================================
  File Name: moduleSupportEnquiry.js
  Description: SupportEnquiry Module
  ----------------------------------------------------------------------------------------
  Item Name: Support(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


import state from './moduleSupportEnquiryState'
import mutations from './moduleSupportEnquiryMutations'
import actions from './moduleSupportEnquiryActions'
import getters from './moduleSupportEnquiryGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

