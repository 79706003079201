/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import _, { identity } from "lodash";

export default {
  addRegionalDirector({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addRegionalDirector(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateRegionalDirector({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateRegionalDirectorData(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /* get country list */
  getDirectorAllCountries({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorAllCountries()
        .then(response => {
          let countries = response.data.data;
          let stateDefault = {
            label: "All",
            value: "all"
          };

          _.forEach(countries, function(value) {
            value.label = value.name;
            value.value = value.name;
            _.forEach(value.states, function(stateValue) {
              stateValue.label = stateValue.name;
              stateValue.value = stateValue.name;
            });
            value.states.unshift(stateDefault);
          });
          let countryDefault = {
            label: "All",
            value: "all",
            states: [
              {
                label: "All",
                value: "all"
              }
            ]
          };
          countries.unshift(countryDefault);
          commit("SET_COUNTRY_OPTIONS", countries);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /* get country list */
  getDirectorSpeceficCountries({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorSpecificCountries()
        .then(response => {
          let countries = response.data.data;
          let stateDefault = {
            label: "All",
            value: "all"
          };

          _.forEach(countries, function(value) {
            value.label = value.name;
            value.value = value.name;
            _.forEach(value.states, function(stateValue) {
              stateValue.label = stateValue.name;
              stateValue.value = stateValue.name;
            });
            value.states.unshift(stateDefault);
          });
          let countryDefault = {
            label: "All",
            value: "all",
            states: [
              {
                label: "All",
                value: "all"
              }
            ]
          };
          countries.unshift(countryDefault);
          commit("SET_DIRECTOR_COUNTRY_OPTIONS", countries);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRegionalDirectors({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getRegionalDirectors()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRegionalDirectorDetail({ commit }, directorId) {
    return new Promise((resolve, reject) => {
      jwt
        .getRegionalDirectorById(directorId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRegionalDirectorLearningCentreList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchRegionalDirectorLearningCentreList(data)
        .then(response => {
          //commit('SET_CENTERS', response.data.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getRegionalDirectorsOfLearningCentre({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchRegionalDirectorsOfLearningCentre(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeCenterFromRegionalDirector({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .removeCenterFromRegionalDirector(data)
        .then(response => {
          //commit('SET_CENTERS', response.data.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getRegionalDirectorsListOfDirector({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getRegionalDirectorsList(data)
        .then(response => {
          //commit('SET_CENTERS', response.data.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  directorSignup({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .directorSignup(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
