import jwt from "../../http/requests/auth/jwt/index.js";
import jwtCA from "../../http/requests/auth/jwt/centerAdmin";
import _ from "lodash";

export default {

  saveNewsletter({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createNewsletter(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchNewsletterList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .newsletterList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRoomList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchRoomList()
        .then((response) => {
          let centre = response.data.data;
          let roomDefault = {label: 'All', value: 'all'};
          _.forEach(centre, function (value) {
            value.label = value.name;
            value.value = value._id;
            _.forEach(value.roomsData, function (roomValue) {
              roomValue.label = roomValue.name;
              roomValue.value = roomValue._id;
            })
            value.roomsData.unshift(roomDefault);
          });
          // let centreDefault = {label: 'Select a learning center', value: 'all', rooms: [{label: '', value: 'all'}]};
          // centre.unshift(centreDefault);
          commit('SET_CENTRE_OPTIONS', centre)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageToNewsletter({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .uploadImage(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageToNewsletterActivity({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .uploadPhotoNewsletterActivity(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActivitiesList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchActivities(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNewsletterById({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .newsletterDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateNewsletter({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editNewsletterDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateNewsletterActivity({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editNewsletterActivity(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeNewsletterActivity({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .removeActivityFromNewsletter(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteNewsletterActivityPhoto({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .removeNewsletterActivityPhoto(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  sendNewsletterToParents({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .sentToParents(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //Center Admin
  fetchNewsletterListCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .newsletterList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRoomListCenterAdmin({commit}) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchRoomList()
        .then((response) => {
          let centre = response.data.data;
          let roomDefault = {label: 'All', value: 'all'};
          _.forEach(centre, function (value) {
            value.label = value.name;
            value.value = value._id;
            _.forEach(value.roomsData, function (roomValue) {
              roomValue.label = roomValue.name;
              roomValue.value = roomValue._id;
            })
            value.roomsData.unshift(roomDefault);
          });
          let centreDefault = {label: 'All', value: 'all', rooms: [{label: 'All', value: 'all'}]};
          centre.unshift(centreDefault);
          commit('SET_CENTRE_OPTIONS', centre)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActivitiesListCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchActivities(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveNewsletterCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .createNewsletter(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImageToNewsletterCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .uploadImage(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNewsletterByIdCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .newsletterDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateNewsletterCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .editNewsletterDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendNewsletterToParentsCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .sentToParents(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNewsletterByRoomIdAndParentId({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getNewsletterByRoomIdAndParentId(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },
  fetchNewsletterByRoomId({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getNewsletterByRoomId(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },
  fetchNewsletterDetailById({commit}, newsletterId) {
    return new Promise((resolve, reject) => {
      jwt
        .getNewsletterDetailById(newsletterId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTeacherNewsletterDetailById({commit}, newsletterId) {
    return new Promise((resolve, reject) => {
      jwt
        .getTeacherNewsletterDetailById(newsletterId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
