/*=========================================================================================
  File Name: moduleChildrenStoriesActions.js
  Description: children stories Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Children's Stories(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
export default {
/*get children's stories list */
  getChildrenStoryList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchChildrenStories(data.sId, data.info)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*delete children's story*/
  deleteChildrenStoriesQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteQuestion(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*create new children's story*/
  saveChildrenStory({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*update children's story*/
  editChildrenStory({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getChildStoryById({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildStoryByStoryId(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

    },

  getPublishedChildStoryById({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getPublishedChildStoryByChildId(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  approveChildStory({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .approveChildStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  rejectChildStory({ commit }, storyId) {
    return new Promise((resolve, reject) => {
      jwt
        .rejectChildStory(storyId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  addChildStoryComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveChildStoryComment(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  fetchChildStories({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildStories(childId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },
  getChildrenStoriesSetList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchChildrenStoriesSet(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLearningCentreList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getLearningCentre()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveChildrenStorySet({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveStorySet(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  editChildrenStorySet({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editStorySet(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteChildrenStoriesSet({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteQuestionSet(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*For Parent*/
  fetchParentChildStoryById({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentChildStoryByStoryId(childId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },
  /*
    For Parent
    Get child stories set with presets by learning center id
  */
  fetchParentChildStoryPresetByCenterId({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentChildStoryPresetByCenterId(childId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
    For Parent
    Store child stories
  */
  storeChildStoryForParent({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .parentStoreChildStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
      For Parent
      Update child stories
    */
  updateChildStoryForParent({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .parentUpdateChildStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
    For Parent
    Store child stories
  */
  deleteChildStoryForParent({commit}, storyId) {
    return new Promise((resolve, reject) => {
      jwt
        .parentDeleteChildStory(storyId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
    For Parent
    Parent response Store child stories
  */
  storeChildStoryResponseParent({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveChildStoryResponseParent(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },
}
