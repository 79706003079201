/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  getAllEylfSet({commit}, filter) {
    return new Promise((resolve, reject) => {

      jwt
        .getEylfSets(filter)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  },


  getEylfSetById({commit}, setId) {
    return new Promise((resolve, reject) => {

      jwt
        .getEylfSetById(setId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  getEylfs({commit}, filter) {
    return new Promise((resolve, reject) => {

      jwt
        .getEylfs(filter.stateId, filter.categoryId)
        .then((response) => {
          commit('SET_EYLFS', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  storeEylf({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .storeEylf(data)
        .then((response) => {
          commit('ADD_EYLF', response.data.data)
          resolve(response)
        }).catch((error) => {
        reject(error)
      });
    })
  },

  updateEylf({commit}, data) {
    return new Promise((resolve, reject) => {

      jwt
        .updateEylf(data)
        .then((response) => {
          commit('UPDATE_EYLF', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  deleteEylf({commit}, eylfId) {
    return new Promise((resolve, reject) => {

      jwt
        .deleteEylf(eylfId)
        .then((response) => {
          commit('DELETE_EYLF', eylfId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  },


  setEylfType({commit}, eylfType) {
    commit('SET_EYLF_TYPE', eylfType)
  },
  setEylfCategory({commit}, category) {
    commit('SET_EYLF_Category', category)
  },

  getStateInfo({commit}, stateId) {
    return new Promise((resolve, reject) => {
      jwt
        .getStateInfo(stateId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addEylfSet({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addEylfSet(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*EYLF SET DELETE*/
  deleteEYLFSetById({commit}, setId) {
    return new Promise((resolve, reject) => {
      jwt
        .removeEYLFSetById(setId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteStateById({commit}, data) {

    return new Promise((resolve, reject) => {

      jwt
        .deleteStateByStateId(data)
        .then((response) => {
          commit('DELETE_STATE', data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
