import state from './moduleCenterAdminState.js'
import mutations from './moduleCenterAdminMutations.js'
import actions from './moduleCenterAdminActions.js'
import getters from './moduleCenterAdminGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

