/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import axios from "../../http/axios/index.js"
//import _ from 'lodash';

export default {


  addSelfAssessment({commit}, data) {

    return new Promise((resolve, reject) => {

      jwt
        .addSelfAssessment(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addSelfAssessmentFastCheck({commit}, data) {

    return new Promise((resolve, reject) => {

      jwt
        .addSelfAssessmentFastCheck(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAssessments({commit}, teacherId) {

    return new Promise((resolve, reject) => {

      jwt
        .getAssessments(teacherId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAssessmentsFastCheck({commit}, teacherId) {
    return new Promise((resolve, reject) => {
      jwt
        .getAssessmentsFastCheck(teacherId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDocumentLogs({commit},option) {

    return new Promise((resolve, reject) => {

      jwt
        .getDocumentViewLogs(option)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDocumentLogsFastCheck({commit},option) {

    return new Promise((resolve, reject) => {

      jwt
        .getDocumentViewLogsFastCheck(option)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveNote({commit},data) {

    return new Promise((resolve, reject) => {

      jwt
        .saveViewNote(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveNoteFastCheck({commit},data) {

    return new Promise((resolve, reject) => {

      jwt
        .saveViewNoteFastCheck(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },





}
