/*=========================================================================================
  File Name: moduleChildrenStoriesMutations.js
  Description: children stories Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Children's Stories(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

export default {
    SET_USERS(state, stories) {
        state.storiesData = stories
    },
}
