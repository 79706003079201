/*=========================================================================================
  File Name: moduleUserSubscriptionsGetter.js
  Description:  UserSubscriptions Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: User Subscriptions
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


export default {}
