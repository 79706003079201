/*=========================================================================================
  File Name: moduleChildrenStoriesGetters.js
  Description: children stories Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Children's Stories(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

export default {}
