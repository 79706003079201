/*=========================================================================================
  File Name: moduleUserSubscriptions.js
  Description: UserSubscriptions Module
  ----------------------------------------------------------------------------------------
  Item Name: User Subscriptions
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


import state from './moduleUserSubscriptionsState'
import mutations from './moduleUserSubscriptionsMutations'
import actions from './moduleUserSubscriptionsActions'
import getters from './moduleUserSubscriptionstGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

