/*=========================================================================================
  File Name: moduleSupportEnquiryActions.js
  Description:  SupportEnquiry Module Action
  ----------------------------------------------------------------------------------------
  Item Name: Support(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import jwtCA from "../../http/requests/auth/jwt/centerAdmin";

export default {
  /*get support enquiries list */
  getSupportEnquiryList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchSupportList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
/* get support enquiry details */
  getSupportEnquiryDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchEnquiryDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* Resolve support enquiry details */
  resolveSupportEnquiryDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .resolveSupportEnquiry(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* save support contact */
  saveSupportContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .saveContact(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /* get super admin detail */
  superAdminDetailsForSupport({ commit }) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getSuperAdminDetails()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
