/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_COUNTRY_OPTIONS(state, countryOptions) {
    state.countryOptions = countryOptions
  },
  SET_ROOM_OPTIONS(state, roomOptions) {
    state.roomOptions = roomOptions
  },

  SET_CENTERS_OF_GROUP(state, centers) {
    state.centersOfGroup = centers
  },

  ADD_CENTERS_OF_GROUP(state, center) {
    state.centersOfGroup.unshift(center)
  },
  UPDATE_CENTERS_OF_GROUP(state, center) {
    const centerIndex = state.centersOfGroup.findIndex((u) => u._id == center._id)
    Object.assign(state.centersOfGroup[centerIndex], center)
  },
  DELETE_CENTERS_OF_GROUP(state, itemId) {
    const centerIndex = state.centersOfGroup.findIndex((u) => u._id == itemId)
    state.centersOfGroup.splice(centerIndex, 1)
  },
  SET_CENTERS(state, centers) {
    state.centers = centers
  },
  SET_TEMP_CHILD_DATA(state, childData) {
    state.tempChildData = childData;
  },
  UNSET_TEMP_CHILD_DATA(state) {
    state.tempChildData = '';
  }
}
