import jwt from "../../http/requests/auth/jwt/index.js"
import _ from "lodash";

export default {

getNotifications({commit},userId){
    return new Promise((resolve, reject) => {
      jwt
        .getAllNotifications(userId)
        .then((response) => {
          let data = response.data.data;
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

},
markNotificationsRead({commit},notificationId){
    return new Promise((resolve, reject) => {
      jwt
        .changeNotificationsRead(notificationId)
        .then((response) => {
          let data = response.data.data;
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

},


}
