/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import _, {identity} from 'lodash';

export default {

  countCenters({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .countCenter()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addCenter({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addCenter(data)
        .then((response) => {
          const center = response.data.data;
          center.country = {};
          center.country.name = data.get("country");
          commit('ADD_CENTERS_OF_GROUP', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateCenter({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateCenterData(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteCenter({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteCenter(centerId)
        .then((response) => {
          if (response.status === 200) {
            commit('DELETE_CENTERS_OF_GROUP', centerId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* get country list */
  getAllCountries({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getAllCountries()
        .then((response) => {
          let countries = response.data.data;
          let stateDefault = {label: 'All', value: 'all'};

          _.forEach(countries, function (value) {
            value.label = value.name;
            value.value = value.name;
            _.forEach(value.states, function (stateValue) {
              stateValue.label = stateValue.name;
              stateValue.value = stateValue.name;
            })
            value.states.unshift(stateDefault);
          });
          let countryDefault = {label: 'All', value: 'all', states: [{label: 'All', value: 'all'}]};
          countries.unshift(countryDefault);
          commit('SET_COUNTRY_OPTIONS', countries)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCenterByGroup({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCentersOfGroup(data)
        .then((response) => {
          commit('SET_CENTERS_OF_GROUP', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getLearningCentreList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchLearningCentreList(data)
        .then((response) => {
          commit('SET_CENTERS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  imageUpload({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addImage(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCenterById({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterDetailsById(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCenterAdminList({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterAdmin(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCenterDetailList({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterNumberList(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCenterStatus({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .editCenterStatus(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addCenterAdmin({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addAdminCenter(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAdminDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterAdminDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  editAdminDetails({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editCenterAdminDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFilterTeacherList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getFilterTeacherList()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTeachersList({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterTeacherList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getChildrenList({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterChildrenList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRoomListAdmin({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterRoomsList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /**
   * Director
   **/
  getDirectorLearningCentreList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchDirectorLearningCentreList(data)
        .then((response) => {
          commit('SET_CENTERS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDirectorList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirector()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addDirectorCenter({
                      commit
                    }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addDirectorCenter(data)
        .then((response) => {
          const center = response.data.data;
          if (center) {
            center.country = {};
            center.country.name = data.get("country");
            commit('ADD_CENTERS_OF_GROUP', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorCenterById({
                          commit
                        }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterDetailsById(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateDirectorCenter({
                         commit
                       }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateDirectorCenterData(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteDirectorCenter({
                         commit
                       }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteDirectorCenter(centerId)
        .then((response) => {
          if (response.status === 200) {
            commit('DELETE_CENTERS_OF_GROUP', centerId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDirectorAdminDetails({
                            commit
                          }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterAdminDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addDirectorCenterAdmin({
                           commit
                         }, data) {

    return new Promise((resolve, reject) => {
      jwt
        .addDirectorAdminCenter(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  editDirectorAdminDetails({
                             commit
                           }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editDirectorCenterAdminDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDirectorTeachersList({
                            commit
                          }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterTeacherList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorCenterTeacherList({
                                 commit
                               }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterTeacherList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorCenterChildrenList({
                                  commit
                                }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterChildrenList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAllLearningCenterListDirector({ commit }, data) {
  return new Promise((resolve, reject) => {
    jwt
      .httpGetAllLearningCenterListDirector(data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
  },
  /* getDirectorRoomList({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterRoomsList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }, */



  getCenterWithRoomInfo({commit}, directorId) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterWithRoomInfoDetail(directorId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDirectorCenterRoomsList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterRoomsList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorCenterAdminList({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterAdmin(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  manageNqsContentByCenterId({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveContentByCenterId(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchEylfChecklistByCenterId({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getEylfCheckListByCenterId(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNqsContentByCenterId({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getContentByCenterId(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorCenterDetailList({
                                commit
                              }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterNumberList(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  getFreeTeacherOfCenter({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getFreeTeacherOfCenter(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFreeChildrenOfCenter({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getFreeChildrenOfCenter(centerId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  /**
   * Director
   **/
  getDirectorTeacherList({
                           commit
                         }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorTeacherList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorTeacherDetails({
                              commit
                            }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorTeacherDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateDirectorTeacher({
                          commit
                        }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editDirectorTeacherDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteDirectorTeacher({
                          commit
                        }, teacherId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteDirectorTeacher(teacherId)
        .then((response) => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* get room list */
  getDirectorRooms({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorRooms(data)
        .then((response) => {
          let rooms = response.data.data;

          _.forEach(rooms, function (value) {
            value.value = value._id;
            value.label = value.name;
          });
          let roomDefault = {
            label: 'Select room',
            value: '',

          };
          rooms.unshift(roomDefault);
          commit('SET_ROOM_OPTIONS', rooms)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addDirectorTeacher({
                       commit
                     }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addDirectorTeacher(data)
        .then((response) => {
          const center = response.data.data;
          center.country = {};
          center.country.name = data.get("country");
          commit('ADD_CENTERS_OF_GROUP', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  /**
   * Children
   **/
  getDirectorChildrenList({
                            commit
                          }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorChildrenList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addDirectorChild({
                     commit
                   }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addDirectorChild(data)
        .then((response) => {
          const center = response.data.data;
          center.country = {};
          center.country.name = data.get("country");
          commit('ADD_CENTERS_OF_GROUP', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorChildDetails({
                            commit
                          }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorChildDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateDirectorChild({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editDirectorChildDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteDirectorChild({commit}, teacherId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteDirectorChild(teacherId)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /* get country list */
  getDirectorAllCountries({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorAllCountries()
        .then((response) => {
          let countries = response.data.data;
          let stateDefault = {
            label: 'All',
            value: 'all'
          };

          _.forEach(countries, function (value) {
            value.label = value.name;
            value.value = value.name;
            _.forEach(value.states, function (stateValue) {
              stateValue.label = stateValue.name;
              stateValue.value = stateValue.name;
            })
            value.states.unshift(stateDefault);
          });
          let countryDefault = {
            label: 'All',
            value: 'all',
            states: [{
              label: 'All',
              value: 'all'
            }]
          };
          countries.unshift(countryDefault);
          commit('SET_COUNTRY_OPTIONS', countries)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* get age group list */
  getAgeGroup({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getAgeGroup()
        .then((response) => {
          let ageGrps = response.data.data;

          _.forEach(ageGrps, function (value) {
            value.label = value.ageGroup;
            value.value = value._id;
          });
          let ageGrpsDefault = {
            label: 'Select age group',
            value: '',

          };
          ageGrps.unshift(ageGrpsDefault);
          resolve(ageGrps)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* get  Director parent list*/
  getDirectorParentList({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorParentList(id)
        .then((response) => {
          let parentList = response.data.data.docs;
          let parentListDefault = {
            label: 'Add new parent',
            value: '',
          };

          if (parentList.length > 0) {

            _.forEach(parentList, function (value) {
              value.label = value.fullName;
              value.value = value._id;
            });

            parentList.unshift(parentListDefault);
          } else {
            parentList.push(parentListDefault);

          }
          resolve(parentList)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
   /* get Parent list from learing center id */
  getParentByLearningCenterId({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchParentByLearningCenterId(centerId)
        .then((response) => {
          let parentList = response.data.data;
          let parentListDefault = {
            label: 'Add new parent',
            value: '',
          };

          if (parentList.length > 0) {

            _.forEach(parentList, function (value) {
              value.label = value.fullName;
              value.value = value._id;
            });

            parentList.unshift(parentListDefault);
          } else {
            parentList.push(parentListDefault);

          }
          resolve(parentList)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async setTempChildData({commit}, data) {
    return await commit('SET_TEMP_CHILD_DATA', data);
  },
  async unSetTempChildData({commit}) {
    return await commit('UNSET_TEMP_CHILD_DATA');
  },

  getCentreByRegion({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getRegionCentre(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCentreByCountry({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCountryCentre(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  resetUserPassword({commit}, userId) {
    return new Promise((resolve, reject) => {
      jwt.resetUserPassword(userId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDirectorParentRelationToChild({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt.getDirectorParentRelationToChild(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getLearningCenterRooms({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCentreAdminRooms(data)
        .then(res => {
          let rooms = res.data.data;
          _.forEach(rooms, function (value) {
            value.value = value._id;
            value.label = value.name;
          });
          let roomDefault = {
            label: 'Select room',
            value: '',

          };
          rooms.unshift(roomDefault);
          commit('SET_ROOM_OPTIONS', rooms)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getLearningCenterRoomsForNqs({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCentreAdminRoomsDetailForNqs(data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  getLearningCenterRoomsWithTeacher({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCentreAdminRoomsWithTeacher(data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },




  fetchRoomsByCenterIds({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getRoomsByCenterIds(data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchTeacherByRoomIds({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getTeacherByRoomIds(data)
        .then(res => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /** Room starts */
  deleteDirectorRoom({
                       commit
                     }, roomId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteDirectorRoom(roomId)
        .then((response) => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateDirectorRoom({
                       commit
                     }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateDirectorRoom(data)
        .then((response) => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**Room Ends */


  /**
   * center dashboard
   */

  /* get room list*/
  async fetchCentreRoomList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCentreAdminRoomList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAdminActivity({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAdminActivity(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  learningStoryDataForDashboardAdmin({commit}, param) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchLearningStoryCenterAdmin(param)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  activeLearningStoryDataForDashboardAdmin({commit}, param) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchActiveLearningStoryCenterAdmin(param)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  fetchCoreSkillCount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCoreSkillCount(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  fetchWaysOfLearningCount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getWaysOfLearningCount(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  fetchStreamCount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getStreamCount(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  /*
    Fetch Core skill compare for director
  */
  fetchDirectorCoreSkillCompare({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCoreSkillCompare(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  /*
  Fetch Ways of learning compare for director
  */
  fetchDirectorWaysOfLearningCompare({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorWaysOfLearningCompare(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
  /*
  Fetch Stream compare for director
  */
  fetchDirectorStreamCompare({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorStreamCompare(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res)
          }
        }).catch((error) => {
        reject(error)
      });
    });
  },
}
