/*=========================================================================================
  File Name: modulePageManagementActions.js
  Description:  PageManagement Module Action
  ----------------------------------------------------------------------------------------
  Item Name: Page Management
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
export default {
  /*get pages list */
  getPagesList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchPageList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
/* get page details */
  getPageDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchPageDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /* Edit Page Details*/
  editPageDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editPage(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //fetch page by slug
  fetchPageDetailsBySlug({commit},slug){
    return new Promise((resolve, reject) => {
      jwt
        .fetchPage(slug)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchContentBySlug({ commit }, slug) {
    return new Promise((resolve, reject) => {
      jwt
        .getContentBySlug(slug)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchFaqDetails({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getFaqData()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPackageInfo({ commit }, slug) {
    return new Promise((resolve, reject) => {
      jwt
        .getPackage(slug)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  submitContactInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .submitInfo(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchInclusionList({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getInclusionData()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paypalRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .paypalApi(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUSPList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getUSPList()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSubscriptionPackages({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getSubscriptionPackage()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  selectPackage({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .packageSelection(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
