/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import _ from "lodash";

export default {
  addStream({ commit }, data) {
    let config = { header: { "Content-Type": "image/png" } };
    return new Promise((resolve, reject) => {
      jwt
        .addStream(data, config)
        .then(response => {
          const data = response.data.data;
          commit("ADD_STREAM", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateStream({ commit }, data) {
    let streamId = data.get("streamId");
    let config = { header: { "Content-Type": "image/png" } };
    return new Promise((resolve, reject) => {
      jwt
        .updateStream(streamId, data, config)
        .then(response => {
          commit("UPDATE_STREAM", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  deleteStream({ commit }, streamId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteStream(streamId)
        .then(response => {
          commit("DELETE_STREAM", streamId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchStreams({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchStreams()
        .then(response => {
          commit("SET_STREAMS", response.data.data);

          let streamOptions = response.data.data;
          _.forEach(streamOptions, function(value) {
            value.label = value.name;
            value.value = value._id;
          });

          commit("SET_STREAM_OPTIONS", streamOptions);
          commit("SET_STREAM_FILTER", streamOptions[0]);

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchParentStreamWithActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentStreamWithActivityCount(data)
        .then(response => {
          commit("SET_STREAMS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchStreamWithActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getStreamWithActivityCount(data)
        .then(response => {
          commit("SET_STREAMS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
