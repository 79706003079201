/*=========================================================================================
  File Name: moduleUserSubscriptionsState.js
  Description: UserSubscriptions Module State
  ----------------------------------------------------------------------------------------
  Item Name: User Subscriptions
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

export default {
  subscriptions: [],
  subscription: ''
}
