/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    SET_COUNTRY_OPTIONS(state, countryOptions) {
        state.countryOptions = countryOptions
    },
    SET_ROOM_OPTIONS(state, roomOptions) {
        state.roomOptions = roomOptions
    },
    SET_GROUPS(state, groups) {
        state.groups = groups
    },

}
