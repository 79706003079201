/*=========================================================================================
  File Name: moduleActivityActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Activity Actions
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  /*  Get Activities List By RoomId and date range*/
  fetchActivities({ commit }, params) {
    return new Promise((resolve, reject) => {
      jwt
        .getActivities(params)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchOnlyActivities({ commit }, params) {
    return new Promise((resolve, reject) => {
      jwt
        .getOnlyActivities(params)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  /*get single activity by activity id*/
  fetchActivityById({ commit }, activityId) {
    return new Promise((resolve, reject) => {
      jwt
        .getActivityById(activityId)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITY", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*Store Activity*/
  storeActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveActivity(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*Update Activity Detail*/
  updateActivityDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editActivityDetail(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*Get Activities By Like Name*/
  fetchActivityByLikeName({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getActivityByLikeName(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*Add Children to Activity*/
  addChildren({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addChildrenToActivity(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*Change Activity Favourite Status*/
  changeFavouriteStatus({ commit }, activityId) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFavouriteStatus(activityId)
        .then(res => {
          if (res.status === 200) {
            // commit('CHANGE_ACTIVITY_FAVOURITE_STATUS', res.data.data)
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*Update Activity*/
  updateActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editActivity(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*Upload Image*/
  uploadImageToActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addImageToActivity(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*Change Activity Favourite Status*/
  getFavouriteActivities({ commit }, roomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getFavouriteActivities(roomId)
        .then(res => {
          if (res.status === 200) {
            commit("SET_FAVOURITE_ACTIVITY", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addActivityToPlanner({ commit }, activityId) {
    return new Promise((resolve, reject) => {
      jwt
        .addActivityToPlanner(activityId)
        .then(res => {
          if (res.status === 200) {
            //commit('SET_FAVOURITE_ACTIVITY', res.data.data)
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*Remove Image from Activity*/
  removeImageFromActivityById({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .removeImageFromActivityById(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*Delete Activity*/
  deleteActivityById({ commit }, activityId) {
    return new Promise((resolve, reject) => {
      jwt
        .removeActivityById(activityId)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /* Edit Follow Up*/
  editFollowUpActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFollowUp(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  editFollowUpStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFollowUpStatus(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  learningStoryForDashboard({ commit }, param) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchLearningStoryForDashboard(param)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchActiveLearningStoryForDashboard({ commit }, param) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchActiveLearningStoryForDashboard(param)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchPlannedActivities({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getPlannedActivities(data)
        .then(res => {
          if (res.status === 200) {
            //commit('SET_ACTIVITIES', res.data.data)
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPlannedActivitiesForTeacher({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getPlannedActivitiesForTeacher(data)
        .then(res => {
          if (res.status === 200) {
            //commit('SET_ACTIVITIES', res.data.data)
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchOutcomeActivities({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getOutcomeActivities(data)
        .then(res => {
          if (res.status === 200) {
            //commit('SET_ACTIVITIES', res.data.data)
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchOutcomeActivitiesForLeadTeacher({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getOutcomeActivitiesForLeadTeacher(data)
        .then(res => {
          if (res.status === 200) {
            //commit('SET_ACTIVITIES', res.data.data)
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchParentDailyDiary({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentDailyDiary(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDailyDiaryByChildId({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDailyDiaryByChildId(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchWeeklyActivityBySlug({ commit }, roomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getWeeklyActivityBySlug(roomId)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchWeeklyActivityBySlugWithTeachers({commit}, data){
    return new Promise((resolve, reject) => {
      jwt
        .getWeeklyActivityBySlug(data.roomId, data.queryParams)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDirectorWeeklyActivityBySlug({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorWeeklyActivityBySlug(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchCenterAdminWeeklyActivityBySlug({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterAdminWeeklyActivityBySlug(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getActivityGrowth({ commit }, data) {
    let { roomId, childId } = data;
    return new Promise((resolve, reject) => {
      jwt
        .getActivityGrowthData(roomId, childId)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDirectorActivityGrowth({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorActivityGrowthData(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCenterAdminActivityGrowth({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterAdminActivityGrowthData(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteFollowUp({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .removeFollowUp(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*get Child  Activity*/
  getChildInteractionList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildInteraction(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*get tagged children activity*/
  fetchTaggedActivityByChildId({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getTaggedActivityByChildId(childId)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*Set activity for parent*/
  async setActivityForParent({ commit }, activity) {
    return await commit("SET_ACTIVITY", activity);
  },

  /*Reset activity */
  async reSetActivityForParent({ commit }) {
    return await commit("RESET_ACTIVITY");
  },

  async setDateRange({ commit }, dateRange) {
    return await commit("SET_DATE_RANGE", dateRange);
  },

  /*Get completed activity by child id*/
  fetchCompletedActivityByChildId({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCompletedActivityByChildId(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*fetch activity by  activity by and child id*/
  fetchActivityByActivityIdAndChildId({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getActivityByActivityIdAndChildId(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*fetch activity by  activity by and child id*/
  parentContactForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .parentContactForm(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*fetch photos of activity by  activity id*/
  fetchActivityPhotosByActivityId({ commit }, activityId) {
    return new Promise((resolve, reject) => {
      jwt
        .getActivityPhotosByActivityId(activityId)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
