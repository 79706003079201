/*=========================================================================================
  File Name: modulePageManagementGetter.js
  Description:  PageManagement Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Page Management
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


export default {}
