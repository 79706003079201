/*=========================================================================================
  File Name: moduleSupportEnquiryState.js
  Description: SupportEnquiry Module State
  ----------------------------------------------------------------------------------------
  Item Name: Support(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

export default {
  supports: [],
  support: ''
}
