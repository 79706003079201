/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import _ from 'lodash';

export default {

   async setSelectRooms({commit}, data) {
    return commit('SET_SELECTED_ROOMS', data)
  },
  async setRedirection({commit}, data) {
    return await commit('SET_REDIRECTION', data)
  },
  async resetRedirection({commit}) {
    return await commit('RESET_REDIRECTION')
  },
  getDirectorRoomList({
                        commit
                      }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorCenterRoomsList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getFilteredDirectorRoomList({
                                commit
                              }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getFilteredDirectorCenterRoomsList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRoomById({commit}, romId) {
    return new Promise((resolve, reject) => {
      jwt
        .getRoomById(romId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAgeGroups({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getAllAgeGroup()
        .then((response) => {

          const ageGroups = response.data.data;
          let ageGroupsDefault = {label: 'All', value: 'all'};

          _.forEach(ageGroups, function (value) {
            value.text = value.ageGroup;
            value.value = value._id;

          });
          ageGroups.unshift(ageGroupsDefault);

          commit('SET_AGE_GROUP_OPTIONS', ageGroups)

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  saveRoom({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveRoom(data)
        .then((response) => {

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRoomsByDirectorId({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorRoomsList(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTeachersByRoomId({commit}, RoomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getTeachersByRoomId(RoomId)
        .then((response) => {
          // console.log("RESP<<", response);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTeachersByRoomIdFilter({commit}, data ) {
    console.log('TLIST>><><', data)
    return new Promise((resolve, reject) => {
      jwt
        .getTeachersByRoomIdFilter(data)
        .then((response) => {
          console.log("RESP<<", response);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getChildrenByRoomId({commit}, RoomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildrenByRoomId(RoomId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchChildrenByMultipleRooms({commit}, query) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchChildrenByMultipleRooms(query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
