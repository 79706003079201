/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const mutations = {


  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu


  COMPRESS_NAV_MENU(state, value) {
    state.isCompressed = value
    console.log(state.isCompressed);
  },
  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },


  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex((item) => item.url == payload.url)

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
    }
    else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url == payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp                 = false
    let lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited    = state.starredPages.slice(0, 10)
    state.starredPages           = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay       = val },
  UPDATE_PRIMARY_COLOR(state, val)   { state.themePrimaryColor = val },
  UPDATE_THEME(state, val)           { state.theme             = val },
  UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY = val },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {

    // Get Data localStorage
    let userInfo = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`)) || state.AppActiveUser
    if (payload) {
      payload.uid = payload._id;
      payload.displayName = payload.fullName;
      payload.photoURL = payload.profileImage || require("@/assets/images/portrait/small/avatar-s-11.jpg");
      payload.status = "online";
      state.AppActiveUser = payload;
    }

    for (const property of Object.keys(payload)) {

      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }

    // Store data in localStorage
    localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`, JSON.stringify(userInfo))
  },

/*
  CHANGE_LOADING(state, isLoading){
    console.log(isLoading,"isLoading");
    state.isLoading     = isLoading;
  }
*/

  UPDATE_PARENT_SELECTED_CHILD(state) {

    // Get Data localStorage
    //localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`, JSON.stringify(userInfo))

    let parent = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`));
    let child = JSON.parse(localStorage.getItem(`child`));

    state.AppParentSelectedChild = child;

    let childParents = child.parents;
    const parentIndex = childParents.findIndex((u) => u.parentId == parent._id)

    if(childParents[parentIndex]){
      if(childParents[parentIndex].hasProfileAccess){
        if(childParents[parentIndex].hasProfileAccess != false){
          state.AppParentHasCurrentChildAccess = true;
        }
      }else{
        state.AppParentHasCurrentChildAccess = true;
      }
    }else{
        state.AppParentHasCurrentChildAccess = false;
    }


    // Store data in localStorage
  },

}

export default mutations

