/*=========================================================================================
  File Name: moduleActivityMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Mutations
  Author: Samir Karmacharya
  Author URL:
==========================================================================================*/


export default {
  SET_PARENTS(state, parents) {
    state.parents = parents
  },

  UPDATE_PARENT(state, data) {
    const index = state.parents.findIndex((u) => u._id == data._id)
    Object.assign(state.parents[index], data)
  },
  DELETE_PARENT(state, itemId) {
    const index = state.parents.findIndex((u) => u._id == itemId)
    state.parents.splice(index, 1)
  },
}

