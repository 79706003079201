/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

export default {

    fetchAgeGroups({commit}) {
        return new Promise((resolve, reject) => {
            jwt
                .getAgeGroups()
                .then((response) => {
                    commit('SET_USERS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    storeAgeGroup({commit}, user) {
        return new Promise((resolve, reject) => {
            jwt
                .saveAgeGroup(user)
                .then((response) => {
                    //commit('ADD_RECORD', user)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    removeAgeGroup({commit}, userId) {
        return new Promise((resolve, reject) => {
            jwt
                .deleteAgeGroup(userId)
                .then((response) => {
                    commit('REMOVE_RECORD', userId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    /*fetchAgeGroupById({commit}, userId) {
        return new Promise((resolve, reject) => {
            jwt
                .getAgeGroupById(userId)
                .then((response) => {
                    //commit('SET_USER', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },*/

    updateAgeGroup({commit}, user) {
        return new Promise((resolve, reject) => {
            jwt
                .editAgeGroup(user)
                .then((response) => {
                    //commit('REMOVE_RECORD', userId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

}
