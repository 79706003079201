// axios
import axios from 'axios'

const domain = `${process.env.VUE_APP_DOMAIN || 'http://localhost:3000'}${process.env.VUE_APP_API_BASE_PATH || '/api/v1/'}`

export default axios.create({
   baseURL: domain
   // baseURL: "http://localhost:3000/v1/"
  // You can add your headers here
})
