/*=========================================================================================
  File Name: moduleActivityActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Actions
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  async fetchAllGuardians({commit},data) {

    return new Promise((resolve, reject) => {
      jwt
        .fetchGuardians(data)
        .then((response) => {
          commit('SET_PARENTS', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteGuardian({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeGuardian(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addGuardian({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createGuardian(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendParentRespondMailFromLearningStory({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .mailParentRespondFromLearningStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateGuardian({commit}, user) {
    return new Promise((resolve, reject) => {
      jwt
        .updateGuardianDetails(user)
        .then((response) => {
          //commit('UPDATE_PARENT', response.data.data)
          console.log("response",response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  guardianDetail({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .guardianDetail(data)
        .then((response) => {
          //commit('UPDATE_PARENT', response.data.data)
          //console.log(response.data.data,"response.data.data")
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  sendAccessEmail({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .sendAccessLinkEmail(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getInvitedList({commit}, childId) {
    return new Promise((resolve, reject) => {
      console.log(childId,"dddd");
      jwt
        .getInvitedList(childId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeParentFromChildAccess({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .removeParentFromChildAccess(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  accessGuardianToChild({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .accessGuardianToChild(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  checkGuardianChild({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .checkGuardianChild(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async updateProfile({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateParentProfile(data)
        .then((response) => {

          let data = response.data.data;

          let userInfo = {
            _id: data.id,
            fullName: data.fullName,
            email: data.email,
            userType: data.userType,
            profileImage: data.profileImage,
            status: "Online",
            roomId:data.roomId
          }

          commit('UPDATE_USER_INFO', userInfo, {root: true})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  inviteContributor({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .inviteContributor(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getChildStoryForContributor({commit}, contributorId) {
    return new Promise((resolve, reject) => {
      jwt
          .getChildStoryForContributor(contributorId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },

  updateChildStoryForContributor({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
          .updateChildStoryForContributor(data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    })
  },
  saveChildStoryForContributor({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveChildStoryForContributor(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getContributorDetail({commit}, contributorId ){
    return new Promise((resolve, reject) => {
      jwt
        .getContributorDetail(contributorId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDailyComment({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .parentDailyComment(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }


}
