/*=========================================================================================
  File Name: moduleUserSubscriptionsMutations.js
  Description: UserSubscriptions Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: User Subscriptions
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


export default {
  SET_SUPPORTS(state, subscriptions) {
    state.subscriptions = subscriptions
  },

  SET_SUPPORT(state, subscription) {
    state.subscription = subscription
  },
}
