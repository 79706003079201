/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import _ from 'lodash';

export default {

  countGroup({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .countGroup()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addGroup({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addGroup(data)
        .then((response) => {
          //commit('SET_WAYS_OF_LEARNING', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateGroup({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateGroup(data)
        .then((response) => {
          //commit('SET_WAYS_OF_LEARNING', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchGroups({commit}, filter) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchGroups(filter)
        .then((response) => {
          //commit('SET_WAYS_OF_LEARNING', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteGroupById({commit}, groupId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteGroup(groupId)
        .then((response) => {
          //commit('SET_WAYS_OF_LEARNING', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  getGroupById({commit}, groupId) {
    return new Promise((resolve, reject) => {
      jwt
        .getGroupById(groupId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateGroupStatus({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editGroupStatus(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAllCountries({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getAllCountries()
        .then((response) => {
          let countries = response.data.data;
          let stateDefault = {label: 'All', value: 'all'};

          _.forEach(countries, function (value) {
            value.label = value.name;
            value.value = value.name;
            _.forEach(value.states, function (stateValue) {
              stateValue.label = stateValue.name;
              stateValue.value = stateValue.name;
            })
            value.states.unshift(stateDefault);

          });
          // let countryNull = {label: 'None', value: null, states: [{label: 'None', value: null }]};
          // countries.unshift(countryNull);
          let countryDefault = {label: 'All', value: 'all', states: [{label: 'All', value: 'all'}]};
          countries.unshift(countryDefault);
          commit('SET_COUNTRY_OPTIONS', countries)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
