/*=========================================================================================
  File Name: moduleActivityActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Activity Actions
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  /*  Get Activities List By RoomId and date range*/
  saveInteraction({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveInteraction(data)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*  Get Activities List By RoomId and date range*/
  getInteraction({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getInteraction(childId)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**CenterAdmin */
  /*  Get Activities List By RoomId and date range*/
  saveCenterAdminInteraction({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveCenterAdminInteraction(data)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*  Get Activities List By RoomId and date range*/
  getCenterAdminInteraction({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterAdminInteraction(childId)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**teacher */
  /*  Get Activities List By RoomId and date range*/
  saveTeacherInteraction({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveTeacherInteraction(data)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*  Get Activities List By RoomId and date range*/
  getTeacherInteraction({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getTeacherInteraction(childId)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*  Get Activities List By RoomId and date range*/
  getTeacherInteractionFromOtherChild({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getTeacherInteractionFromOtherChild(childId)
        .then(res => {
          if (res.status === 200) {
            commit("SET_ACTIVITIES", res.data.data);
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
