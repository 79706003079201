import axios from "../../../axios/index.js";
import store from "../../../../store/store.js";
import router from "./../../../../router";

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(refreshData) {
  // subscribers = subscribers.filter(callback => callback(refreshData))
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`,
    refreshData.accessToken
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
    refreshData.user.firstName
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
    JSON.stringify(refreshData.user)
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`,
    "true"
  );
  store.commit("UPDATE_USER_INFO", refreshData.user, {
    root: true
  });
  store.commit("SET_BEARER", refreshData.accessToken);
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
        );
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // axios.interceptors.request.use(function(config) {

    //   //.$vs.loading()
    //   console.log('Start Ajax Call');
    //   /*store.dispatch("changeLoading", true);
    //   console.log('Start Ajax Call');*/
    //   return config;
    // }, function(error) {
    //   // Do something with request error
    //   console.log('Error');
    //   return Promise.reject(error);
    // });

    axios.interceptors.response.use(
      function(response) {
        //this.$vs.loading.close()
        //console.log("complete");
        //store.dispatch("changeLoading", false);
        return response;
      },
      function(error) {
        //this.$vs.loading.close()
        /*console.log("complete");
      store.dispatch("changeLoading", false);*/

        if (!error.response) {
          return Promise.reject({
            message: "Please check your internet connection."
          });
        }
        const {
          config,
          response: { status }
        } = error;

        if (config.url.includes("login") && status === 401) {
          return Promise.reject({
            message: error.response.data.message
          });
        }

        if (config.url.includes("refreshToken") && status === 401) {
          if (config.url.includes("/admin/")) {
            router.push("/admin/login");
          } else if (config.url.includes("/teacher/")) {
            router.push("/teacher/login");
          }
        }

        const originalRequest = config;
        if (status === 401) {
          // if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            const refreshToken = refreshToken;
            isAlreadyFetchingAccessToken = true;
            store.dispatch("auth/fetchAccessToken").then(refreshToken => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(refreshToken);
            });
          }

          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = "Bearer " + access_token;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   * Dashboard
   */
  getCentreAdminRoomList(id) {
    return axios.get("/centerAdmin/dashboard/room/List/" + id);
  },

  getCenterAdminTeacherByRoomIds(data) {
    return axios.get("/centerAdmin/get-teacher-rooms?roomIds=" + data);
  },
  fetchChildrenByMultipleRooms(query) {
    return axios.get("centerAdmin/child-rooms?query=" + query);
  },
  fetchAdminActivity(data) {
    return axios.get("/centerAdmin/dashboard/activity" + data);
  },
  fetchLearningStoryCenterAdmin(params) {
    return axios.get("/centerAdmin/dashboard/learning-stories-data" + params);
  },
  getCentreAdminRooms(id) {
    return axios.get("/centerAdmin/getRooms/" + id);
  },
  /**
   * Teacher starts
   */
  getCenterAdminTeacherList(data) {
    return axios.get(
      "/centerAdmin/teachers/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&learningCenter=" +
        data.learningCenterId
    );
  },
  addCenterAdminTeacher(data) {
    return axios.post("/centerAdmin/teacher/add", data);
  },
  getCenterAdminTeacherDetails(id) {
    return axios.get("/centerAdmin/teacher/details/" + id);
  },
  editCenterAdminTeacherDetails(data) {
    return axios.post("/centerAdmin/teacher/edit/", data);
  },
  deleteCenterAdminTeacher(teacherId) {
    return axios.get("/centerAdmin/teacher/delete/" + teacherId);
  },
  importTeacherCsv(data) {
    return axios.post("/centerAdmin/teacher/import", data);
  },
  /** Teacher Ends */
  /** Children Starts */

  getCenterAdminChildrenList(data) {
    return axios.get(
      "/centerAdmin/children/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        /*  "&country=" +
      data.country +
      "&state=" +
      data.state + */
        "&director=" +
        data.directorId
    );
  },

  addCenterAdminChild(data) {
    return axios.post("/centerAdmin/children/add", data);
  },
  getCenterAdminChildDetails(id) {
    return axios.get("/centerAdmin/children/details/" + id);
  },
  editCenterAdminChildDetails(data) {
    return axios.post("/director/children/edit/", data);
  },
  deleteCenterAdminChild(id) {
    return axios.get("/centerAdmin/children/delete/" + id);
  },
  importChildrenCsv(data) {
    return axios.post("/centerAdmin/children/import", data);
  },

  /** Children Ends */

  /** Newsletters */
  fetchRoomList() {
    return axios.get("centerAdmin/newsletters/getRoomList");
  },
  createNewsletter(data) {
    return axios.post("centerAdmin/newsletters/create", data);
  },
  newsletterList(data) {
    return axios.get(
      "centerAdmin/newsletters/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&directorId=" +
        data.directorId
    );
  },
  uploadImage(data) {
    return axios.post(
      "centerAdmin/newsletters/uploadImage",
      data.obj,
      data.header
    );
  },
  fetchActivities(data) {
    return axios.post("centerAdmin/newsletters/getActivityList", data);
  },
  newsletterDetails(id) {
    return axios.get("centerAdmin/newsletters/details/" + id);
  },
  editNewsletterDetails(data) {
    return axios.post("centerAdmin/newsletters/edit/" + data.id, data.data);
  },
  sentToParents(data) {
    return axios.post("centerAdmin/newsletters/sendNewsletter", data);
  },

  /** Newsletter ends */

  /** Rooms */
  deleteCenterAdminRoom(id) {
    return axios.get("/centerAdmin/room/delete/" + id);
  },
  updateCenterAdminRoom(data) {
    return axios.post("/centerAdmin/room/edit/", data);
  },
  /** Rooms Ends */

  /**
   * fast check
   */
  getAdminFastChecksDevelopment(filter) {
    return axios.post(
      "/centerAdmin/professional-development/get-fast-checks-development",
      filter
    );
  },
  deleteDirectorFastCheckDevelopment(data) {
    return axios.post(
      "/centerAdmin/professional-development/delete-fast-check-development/",
      data
    );
  },
  fetchFastChecks() {
    return axios.get("/centerAdmin/get-fast-checks/");
  },
  getFastChecksDevelopment(data) {
    return axios.post(
      "/centerAdmin/professional-development/get-fast-checks-development?search=" +
        data.search,
      data.data
    );
  },
  getFastChecksDetails(id) {
    return axios.get(
      "/centerAdmin//professional-development/get-fast-check-details/" + id
    );
  },
  fetchNQSFastCheck(centerId) {
    return axios.get("/centerAdmin/get-nqs-fast-check/" + centerId);
  },
  fetchNQSTeacherDevelopmentLogs(params) {
    return axios.get(
      "/centerAdmin/get-nqs-teacher-development-logs-check/" + params
    );
  },
  fetchNQSSelfAssessmentCountByCenterId(params) {
    return axios.get("/centerAdmin/get-nqs-self-assessments-count/" + params);
  },
  fetchCoreSkills() {
    return axios.get("/centerAdmin/get-core-skills/");
  },
  getCoreSkillDevelopment(data) {
    return axios.post(
      "/centerAdmin/professional-development/get-core-skills-development?search=" +
        data.search,
      data.data
    );
  },


  /**
   * core skill ends here
   */

  /**
   *  NQS
   */
  getNqsOutcomeListGenerate(data) {
    return axios.get(
      "/centerAdmin/get-nqs-outcome-list?" +
        "learningCenterId=" +
        data.learningCenterId +
        "&state=" +
        data.state +
        "&startDate=" +
        data.startDate +
        "&endDate=" +
        data.endDate
    );
  },

  getNqsChildrenCountHavingFastCheckScore(data) {
    return axios.get(
      "/centerAdmin/get-nqs-children-count-having-fastcheck-score"+data
    );
  },



  /**
   *  NQS Activities
   */
  getNqsActivitiesListGenerate(data) {
    return axios.get(
      "/centerAdmin/get-nqs-activities-list?" +
        "learningCenterId=" +
        data.learningCenterId +
        "&state=" +
        data.state +
        "&startDate=" +
        data.startDate +
        "&endDate=" +
        data.endDate
    );
  },

  /**
   *  NQS ChildStory
   */
  fetchNqsChildStoryCount(centerId) {
    return axios.get("/centerAdmin/get-nqs-child-story-count/" + centerId);
  },

  /**
   * About us
   */
  getAboutUsDetails(id) {
    return axios.get("/centerAdmin/get-about-us/" + id);
  },
  ImageUpload(data) {
    return axios.post("/centerAdmin/uploadImage", data.obj, data.header);
  },
  updateAboutUs(data) {
    return axios.post("/centerAdmin/update-about-us/" + data.id, data.data);
  },
  saveAboutUs(data) {
    return axios.post("/centerAdmin/create-about-us", data);
  },

  /**
   * support
   */
  saveContact(data) {
    return axios.post("/centerAdmin/save-support-contact", data);
  },
  getSuperAdminDetails() {
    return axios.get("/centerAdmin/get-super-admin-details");
  },

  getCoreSkillCount(data) {
    return axios.get("centerAdmin/core-skill-count?query=" + data);
  },
  getWaysOfLearningCount(data) {
    return axios.get("centerAdmin/ways-of-learning-count?query=" + data);
  },
  getStreamCount(data) {
    return axios.get("centerAdmin/stream-count?query=" + data);
  },

  /*
   Get Center Admin Core skill compare
 */
  getCenterAdminCoreSkillCompare(data) {
    return axios.get("centerAdmin/core-skill-compare2?query=" + data);
  },
  /*
    Get Center Admin  Ways of learning compare
  */
  getCenterAdminWaysOfLearningCompare(data) {
    return axios.get("centerAdmin/ways-of-learning-compare2?query=" + data);
  },
  /*
    Get Center Admin Stream compare
  */
  getCenterAdminStreamCompare(data) {
    return axios.get("centerAdmin/stream-compare2?query=" + data);
  },
  /*
    Generate NQS report
  */
  generateNqsReport(params) {
    return axios.get("centerAdmin/generate-nqs-report" + params, {
      responseType: "blob"
    });
  }
};
