/*=========================================================================================
  File Name: moduleActivityActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Actions
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  /*Update Teacher Profile*/
  async updateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateTeacherProfile(data)
        .then(response => {
          let data = response.data.data;

          let userInfo = {
            _id: data.id,
            fullName: data.fullName,
            email: data.email,
            userType: data.userType,
            profileImage: data.profileImage,
            status: "Online",
            roomId: data.roomId
          };

          commit("UPDATE_USER_INFO", userInfo, { root: true });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*updateTeacherPassword*/
  changePassword({ commit }, user) {
    return new Promise((resolve, reject) => {
      jwt
        .changeTeacherPassword(user)
        .then(response => {
          //commit('REMOVE_RECORD', userId)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*updateTeacherPassword*/
  getEylfsWithLearningStory({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .getEylfsWithLearningStories(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDirectorEylfWithLearningStories({ commit }, query) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorEylfWithLearningStories(query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchCenterAdminEylfWithLearningStories({ commit }, query) {
    return new Promise((resolve, reject) => {
      jwt
        .geCenterAdminEylfWithLearningStories(query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getEylfsWithLearningStoryByRoomId({ commit }, roomId) {
    return new Promise((resolve, reject) => {
      jwt
        .getEylfsWithLearningStoriesByRoom(roomId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addDailyComment({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .saveDailyComment(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateDailyComment({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwt
        .editDailyComment(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getDailyComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchDailyComment(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  saveChildFastCheck({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .storeChildFastCheck(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchChildFastCheckByChildIdAndChildFastCheckId({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildFastCheckByChildIdAndChildFastCheckId(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchChildFastCheckByChildFastCheckId({ commit }, childId) {
    return new Promise((resolve, reject) => {
      jwt
        .getChildFastCheckByChildFastCheckId(childId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTeacherById({ commit }, teacherId) {
    return new Promise((resolve, reject) => {
      jwt
        .getTeacherById(teacherId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
