/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/



export default {

    SET_FAST_CHECKS(state, fastChecks) {
        state.fastChecks = fastChecks
    },

    ADD_FAST_CHECK(state, data) {
        state.fastChecks.unshift(data)
    },
    UPDATE_FAST_CHECK(state, data) {
        const fastCheckIndex = state.fastChecks.findIndex((u) => u._id == data._id)
        Object.assign(state.fastChecks[fastCheckIndex], data)
    },
    DELETE_FAST_CHECK(state, itemId) {
        const fastCheckIndex = state.fastChecks.findIndex((u) => u._id == itemId)
        state.fastChecks.splice(fastCheckIndex, 1)
    },
    SET_RATING(state, rating) {
        state.rating = rating;
    },





    SET_FAST_CHECK_OPTIONS(state, fastCheckOptions) {
        state.fastChecksOptions = fastCheckOptions
    },
    SET_FAST_CHECK_FILTER(state, fastCheckFilter) {
        state.fastCheckFilter = fastCheckFilter
    },
    SET_FAST_CHECKS_DEVELOPMENT(state, fastChecksDevelopments) {
        state.fastChecksDevelopments = fastChecksDevelopments
    },

    ADD_FAST_CHECK_DEVELOPMENT(state, data) {
        if(state.rating == data.rating) {
            state.fastChecksDevelopments.unshift(data)
        }
    },
    DELETE_FAST_CHECK_DEVELOPMENT(state, itemId) {
        const fastChecksDevelopmentIndex = state.fastChecksDevelopments.findIndex((u) => u._id == itemId)
        state.fastChecksDevelopments.splice(fastChecksDevelopmentIndex, 1)
    },
    UPDATE_FAST_CHECK_DEVELOPMENT(state, data) {
        const resource = {
            description: data.description,
            link: data.link,
            title: data.name,
            _id: data.resourceId
        }

        const fastChecksDevelopmentIndex = state.fastChecksDevelopments.findIndex((u) => u._id == data.resourceId)

        if(state.rating == data.rating){
            Object.assign(state.fastChecksDevelopments[fastChecksDevelopmentIndex], resource)
        }else{
            state.fastChecksDevelopments.splice(fastChecksDevelopmentIndex, 1)
        }

    },


}
