/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import axios from "../../http/axios/index.js"
import _ from 'lodash';
import jwtCA from "../../http/requests/auth/jwt/centerAdmin"

export default {


  addFastCheck({commit}, data) {
    return new Promise((resolve, reject) => {

      jwt
        .addFastCheck(data)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data;
            delete data.professionalDevelopmentResources;
            commit('ADD_FAST_CHECK', data)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })

      /*axios.post('admin/save-fast-check', data).then(res => {
          const data = res.data.data;
          delete data.professionalDevelopmentResources;
          commit('ADD_FAST_CHECK',data)
          resolve(res)
      }).catch(error => {
          reject(error)
      })*/
    })
  },

  updateFastCheck({commit}, data) {
    let fastCheckId = data.fastCheckId;
    return new Promise((resolve, reject) => {

      jwt
        .updateFastCheck(fastCheckId, data)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_FAST_CHECK', response.data.data)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })

      /*axios.post('/admin/update-fast-check/' + fastCheckId, data).then(res => {
          console.log(res.data.data,"asd");
          commit('UPDATE_FAST_CHECK', res.data.data)
          resolve(res)
      }).catch(error => {
          reject(error)
      })*/
    })
  },
  deleteFastCheck({commit}, fastCheckId) {
    return new Promise((resolve, reject) => {

      jwt
        .deleteFastCheck(fastCheckId)
        .then((response) => {
          if (response.status === 200) {
            commit('DELETE_FAST_CHECK', fastCheckId)
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })


      /*axios.get('/admin/delete-fast-check/' + fastCheckId).then(response => {
          commit('DELETE_FAST_CHECK', fastCheckId)
          resolve(response)
      }).catch((error) => {
          reject(error)
      })*/
    })
  },


  fetchFastChecks({commit}) {

    return new Promise((resolve, reject) => {
      jwt
        .fetchFastChecks()
        .then((res) => {
          if (res.status === 200) {
            commit('SET_FAST_CHECKS', res.data.data)
            let fastChecksOptions = res.data.data;
            _.forEach(fastChecksOptions, function (value) {
              value.label = value.name;
              value.value = value._id;
            });
            commit("SET_FAST_CHECK_OPTIONS", fastChecksOptions);
            commit("SET_FAST_CHECK_FILTER", fastChecksOptions[0]);
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchRandomFastChecks({commit}, ageGroupId) {

    return new Promise((resolve, reject) => {
      jwt
        .getRandomFastChecks(ageGroupId)
        .then((res) => {
          if (res.status === 200) {
            commit('SET_FAST_CHECKS', res.data.data)
            let fastChecksOptions = res.data.data;
            _.forEach(fastChecksOptions, function (value) {
              value.label = value.name;
              value.value = value._id;
            });
            commit("SET_FAST_CHECK_OPTIONS", fastChecksOptions);
            commit("SET_FAST_CHECK_FILTER", fastChecksOptions[0]);
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeChildFromChildFastCheck({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteChildFromChildFastCheck(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*Fast Check developments*/
  setRating({commit}, rating) {
    commit('SET_RATING', rating)
  },

  getFastChecksDevelopment({commit}, filter) {

    return new Promise((resolve, reject) => {
      jwt
        .getFastChecksDevelopment(filter)
        .then((res) => {
          let data = res.data.data;
          if (Array.isArray(data)) {
            commit('SET_FAST_CHECKS_DEVELOPMENT', []);
          } else {
            commit('SET_FAST_CHECKS_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources);
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteFastCheckDevelopment({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .deleteFastCheckDevelopment(data)
        .then((res) => {
          commit('DELETE_FAST_CHECK_DEVELOPMENT', data.resourceId)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  storeFastCheckDevelopment({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .storeFastCheckDevelopment(data)
        .then((res) => {
          let addedData = res.data.data;
          addedData.rating = data.rating;
          commit('ADD_FAST_CHECK_DEVELOPMENT', addedData)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },


  updateFastCheckDevelopment({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .updateFastCheckDevelopment(data)
        .then((res) => {
          commit('UPDATE_FAST_CHECK_DEVELOPMENT', data)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  storeChildFastCheck({commit}, data) {

    return new Promise((resolve, reject) => {
      jwt
        .addChildFastCheck(data)
        .then((res) => {

          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })

  },

  fetchFastCheckCompare({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getFastCheckCompare(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDirectorFastCheckCompare({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorFastCheckCompare(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCenterAdminFastCheckCompare({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCenterAdminFastCheckCompare(data)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDirectorFastChecksDevelopment({
                                     commit
                                   }, filter) {

    return new Promise((resolve, reject) => {
      jwt
        .getDirectorFastChecksDevelopment(filter)
        .then((res) => {
          let data = res.data.data;
          if (Array.isArray(data)) {
            commit('SET_FAST_CHECKS_DEVELOPMENT', []);
          } else {
            commit('SET_FAST_CHECKS_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources);
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDirectorFastChecks({
                            commit
                          }) {

    return new Promise((resolve, reject) => {
      jwt
        .fetchDirectorFastChecks()
        .then((res) => {
          if (res.status === 200) {
            commit('SET_FAST_CHECKS', res.data.data)
            let fastChecksOptions = res.data.data;
            _.forEach(fastChecksOptions, function (value) {
              value.label = value.name;
              value.value = value._id;
            });

            commit("SET_FAST_CHECK_OPTIONS", fastChecksOptions);
            commit("SET_FAST_CHECK_FILTER", fastChecksOptions[0]);
            //this.getFastCheckDevelopment();
            resolve(res)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteDirectorFastCheckDevelopment({
                                       commit
                                     }, data) {

    return new Promise((resolve, reject) => {
      jwt
        .deleteDirectorFastCheckDevelopment(data)
        .then((res) => {
          commit('DELETE_FAST_CHECK_DEVELOPMENT', data.resourceId)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**
   * center admin insights
   */


  getCenterAdminFastChecksDevelopment({commit}, filter) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getAdminFastChecksDevelopment(filter)
        .then((res) => {
          let data = res.data.data;
          if (Array.isArray(data)) {
            commit('SET_FAST_CHECKS_DEVELOPMENT', []);
          } else {
            commit('SET_FAST_CHECKS_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources);
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCenterAdminFastChecks({commit}) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchFastChecks()
        .then((res) => {
          /*if (res.status === 200) {
            commit('SET_FAST_CHECKS', res.data.data)
            let fastChecksOptions = res.data.data;
            _.forEach(fastChecksOptions, function (value) {
              value.label = value.name;
              value.value = value._id;
            });

            commit("SET_FAST_CHECK_OPTIONS", fastChecksOptions);
            commit("SET_FAST_CHECK_FILTER", fastChecksOptions[0]);
            resolve(res)
          }*/
          resolve(res);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCenterAdminFastChecksResources({commit}, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getFastChecksDevelopment(data)
        .then((res) => {
          let data = res.data.data;
          if (Array.isArray(data)) {
            commit('SET_FAST_CHECKS_DEVELOPMENT', []);
          } else {
            commit('SET_FAST_CHECKS_DEVELOPMENT', res.data.data.professionalDevelopmentResources[0].resources);
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCenterAdminFastChecksDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getFastChecksDetails(id)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  logFastCheckProfessionalDevelopmentForTeacher({commit}, log) {


    return new Promise((resolve, reject) => {
      jwt
        .logFastCheckProfessionalDevelopmentForTeacher(log)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


}
