/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_STREAMS(state, streams) {
    state.streams = streams;
  },
  ADD_STREAM(state, data) {
    state.streams.unshift(data);
  },
  UPDATE_STREAM(state, data) {
    console.log(data, "asd");
    const streamIndex = state.streams.findIndex(u => u._id == data._id);
    Object.assign(state.streams[streamIndex], data);
  },
  DELETE_STREAM(state, itemId) {
    const streamIndex = state.streams.findIndex(u => u._id == itemId);
    state.streams.splice(streamIndex, 1);
  },
  SET_STREAM_OPTIONS(state, streamOptions) {
    state.streamsOptions = streamOptions;
  },
  SET_STREAM_FILTER(state, streamFilter) {
    state.streamsFilter = streamFilter;
  }
};
