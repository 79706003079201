/*=========================================================================================
  File Name: moduleActivityMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Mutations
  Author: Samir Karmacharya
  Author URL:
==========================================================================================*/


export default {
  SET_CHILDREN(state, children) {
    state.children = children

  },
  SET_SELECTED_CHILD_ID(state, childId) {
    state.selectedChildId = childId
  },

  SET_CHILDREN_LIST(state, children) {
    state.parentChildren = children
    if (children.length > 0) {
      state.child = children[0];
    }
  },

  SET_CHILD(state, child) {
    state.child = child
  },
  //
  // SET_FAVOURITE_ACTIVITY(state, activities) {
  //   state.favouriteActivities = activities
  // },
  //
  // CHANGE_ACTIVITY_FAVOURITE_STATUS(state, activity) {
  //   const activityIndex = state.favouriteActivities.findIndex((u) => u._id == activity._id)
  //
  //   if(activityIndex  == "-1") {
  //     state.favouriteActivities.unshift(data)
  //   }else{
  //     state.favouriteActivities.splice(activityIndex, 1)
  //   }
  // },
}

