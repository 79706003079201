import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";

Vue.use(AclInstaller);

let initialRole = "guest";
let userInfo = JSON.parse(
  localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`)
);
if (userInfo && userInfo.userType) {
  initialRole = userInfo.userType;

  if (userInfo.userType == "learningCenter") {
    initialRole = userInfo.userRole;
  }
  if (userInfo.userType == "parent") {
    if (userInfo.userRole == "invitedGuardians") {
      initialRole = userInfo.userRole;
    }
  }
  if (userInfo.userType == "guardians") {
    initialRole = userInfo.userRole;
  }
  if (userInfo.userType == "guardians") {
    initialRole = userInfo.userRole;
  }
}

export default new AclCreate({
  initial: initialRole,
  notfound: "/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    superAdmin: new AclRule("superAdmin").generate(),
    admin: new AclRule("admin").or("superAdmin").generate(),
    learningCenter: new AclRule("learningCenter").generate(),
    centreDirector: new AclRule("centreDirector").generate(),
    centerAdmin: new AclRule("centerAdmin").generate(),
    leadTeacher: new AclRule("leadTeacher").generate(),
    guardians: new AclRule("guardians").or("parent").generate(),
    guardian: new AclRule("guardian").or("parent").generate(),
    teacher: new AclRule("teacher").generate(),
    parent: new AclRule("parent")
      .or("guardian")
      .or("guardians")
      .generate(),
    invitedGuardians: new AclRule("invitedGuardians").generate(),
    parentOrInvitedGuardians: new AclRule("parent")
      .or("guardians")
      .or("invitedGuardians")
      .generate(),
    editor: new AclRule("guest")
      .or("superAdmin")
      .or("admin")
      .or("learningCenter")
      .or("centreDirector")
      .or("centerAdmin")
      .or("leadTeacher")
      .or("teacher")
      .or("parent")
      .or("guardians")
      .or("guardian")
      .or("parent")
      .or("invitedGuardians")
      .generate(),
    public: new AclRule("guest")
      .or("superAdmin")
      .or("admin")
      .or("learningCenter")
      .or("centreDirector")
      .or("centerAdmin")
      .or("leadTeacher")
      .or("teacher")
      .or("parent")
      .or("guardians")
      .or("guardian")
      .or("invitedGuardians")
      .generate()
    //public: new AclRule("guest").generate(),
  }
});
