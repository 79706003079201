/*=========================================================================================
  File Name: moduleChildrenStories.js
  Description: childrenStories Module
  ----------------------------------------------------------------------------------------
  Item Name: Children's Stories(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


import state from './moduleChildrenStoriesState.js'
import mutations from './moduleChildrenStoriesMutations'
import actions from './moduleChildrenStoriesActions.js'
import getters from './moduleChildrenStoriesGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

