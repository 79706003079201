/*=========================================================================================
  File Name: moduleActivityMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Mutations
  Author: Samir Karmacharya
  Author URL:
==========================================================================================*/


export default {
  // SET_ACTIVITIES(state, activities) {
  //   state.activities = activities
  // },
}

