/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import axios from "../../http/axios/index.js";
import _ from "lodash";

export default {
  addWaysOfLearning({ commit }, data) {
    let config = { header: { "Content-Type": "image/png" } };

    return new Promise((resolve, reject) => {
      jwt
        .addWaysOfLearning(data, config)
        .then(response => {
          const data = response.data.data;
          commit("ADD_WAYS_OF_LEARNING", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      /*axios.post('admin/save-ways-of-learning', data, config).then(res => {
          const data = res.data.data;
          commit('ADD_WAYS_OF_LEARNING',data)
          resolve(res)
      }).catch(error => {
          reject(error)
      })*/
    });
  },

  updateWaysOfLearning({ commit }, data) {
    let waysOfLearningId = data.get("waysOfLearningId");
    let config = { header: { "Content-Type": "image/png" } };

    return new Promise((resolve, reject) => {
      jwt
        .updateWaysOfLearning(waysOfLearningId, data, config)
        .then(response => {
          commit("UPDATE_WAYS_OF_LEARNING", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  deleteWaysOfLearning({ commit }, waysOfLearningId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteWaysOfLearning(waysOfLearningId)
        .then(response => {
          commit("DELETE_WAYS_OF_LEARNING", waysOfLearningId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchWaysOfLearning({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchWaysOfLearning()
        .then(response => {
          commit("SET_WAYS_OF_LEARNING", response.data.data);

          let waysOfLearningOptions = response.data.data;
          _.forEach(waysOfLearningOptions, function(value) {
            value.label = value.name;
            value.value = value._id;
          });

          // commit("SET_WAYS_OF_LEARNINGS_OPTIONS", waysOfLearningOptions);
          // commit(
          //   "SET_WAYS_OF_LEARNINGS_STREAM_FILTER",
          //   waysOfLearningOptions[0]
          // );

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchParentWaysOfLearningWithActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getParentWaysOfLearningWithActivityCount(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchWaysOfLearningWithActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getWaysOfLearningWithActivityCount(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
