/*=========================================================================================
  File Name: moduleChildrenStoriesState.js
  Description: children stories Module State
  ----------------------------------------------------------------------------------------
  Item Name: Children's Stories(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

export default {
  storiesData: [],
}
