/*=========================================================================================
  File Name: modulePageManagementMutations.js
  Description: PageManagement Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Page Management
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


export default {
  SET_SUPPORTS(state, pages) {
    state.pages = pages
  },

  SET_SUPPORT(state, page) {
    state.page = page
  },
}
