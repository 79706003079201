/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_WAYS_OF_LEARNING(state, waysOfLearning) {
    state.waysOfLearning = waysOfLearning;
  },
  ADD_WAYS_OF_LEARNING(state, data) {
    state.waysOfLearning.unshift(data);
  },
  UPDATE_WAYS_OF_LEARNING(state, data) {
    const waysOfLearningIndex = state.waysOfLearning.findIndex(
      u => u._id == data._id
    );
    Object.assign(state.waysOfLearning[waysOfLearningIndex], data);
  },
  DELETE_WAYS_OF_LEARNING(state, itemId) {
    const waysOfLearningIndex = state.waysOfLearning.findIndex(
      u => u._id == itemId
    );
    state.waysOfLearning.splice(waysOfLearningIndex, 1);
  },
  SET_WAYS_OF_LEARNING_OPTIONS(state, waysOfLearningOptions) {
    state.waysOfLearningOptions = waysOfLearningOptions;
  },
  SET_WAYS_OF_LEARNING_FILTER(state, waysOfLearningFilter) {
    state.waysOfLearningFilter = waysOfLearningFilter;
  }
};
