/*=========================================================================================
  File Name: moduleSupportEnquiryGetter.js
  Description:  SupportEnquiry Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Support(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


export default {}
