/*=========================================================================================
  File Name: moduleSupportEnquiryMutations.js
  Description: SupportEnquiry Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Support(Admin)
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


export default {
  SET_SUPPORTS(state, supports) {
    state.supports = supports
  },

  SET_SUPPORT(state, support) {
    state.support = support
  },
}
