/*=========================================================================================
  File Name: moduleActivityMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Teacher Mutations
  Author: Samir Karmacharya
  Author URL:
==========================================================================================*/


export default {
  // SET_ACTIVITIES(state, activities) {
  //   state.activities = activities
  // },
  //
  // SET_ACTIVITY(state, activity) {
  //   state.activity = activity
  // },
  //
  // SET_FAVOURITE_ACTIVITY(state, activities) {
  //   state.favouriteActivities = activities
  // },
  //
  // CHANGE_ACTIVITY_FAVOURITE_STATUS(state, activity) {
  //   const activityIndex = state.favouriteActivities.findIndex((u) => u._id == activity._id)
  //
  //   if(activityIndex  == "-1") {
  //     state.favouriteActivities.unshift(data)
  //   }else{
  //     state.favouriteActivities.splice(activityIndex, 1)
  //   }
  // },
}

