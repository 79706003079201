/*=========================================================================================
  File Name: modulePageManagement.js
  Description: PageManagement Module
  ----------------------------------------------------------------------------------------
  Item Name: Page Mangement
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/


import state from './modulePageManagementState'
import mutations from './modulePageManagementMutations'
import actions from './modulePageManagementActions'
import getters from './modulePageManagementGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

