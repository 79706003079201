/*=========================================================================================
  File Name: moduleSettingsActions.js
  Description: Settings Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import jwt from "../../http/requests/auth/jwt";

export default {
// content
  fetchContent({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchContentList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateContent({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateContentDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // unique selling point
  fetchUsp({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchUspList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUsp({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateUspDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  //testimonials
  fetchTestimonial({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchTestimonialList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateTestimonial({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateTestimonialDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addTestimonial({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createTestimonial(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteTestimonial({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeTestimonial(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // FAQ
  fetchFaq({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchFaqList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateFaq({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFaqDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addFaq({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createFaq(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteFaq({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeFaq(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  //subcription package
  fetchSubscription({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchSubscriptionList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSubscription({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateSubscriptionDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchInclusion({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchInclusionList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteInclusion({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeInclusion(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateInclusion({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateInclusionDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addInclusion({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createInclusion(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDirectorSubscription({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchDirectorSubscriptionList(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDirectorCurrentSubscription({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchDirectorSubscription()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDirectorPendingUpgradeSubscription({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getDirectorPendingUpgradeSubscription()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchLandingTestimonialList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getLandingTestimonialList()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })  },
  fetchDirectorPastSubscription({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchPastSubscription()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPastSubscriptionDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchPastSubsDetails(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelSubscription({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .subscriptionCancel(id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
  /*//fetch email details
  fetchEmailDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchEmailTemplateDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // update email content
  updateEmailDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateEmailTemplateDetails(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
    // Fetch Tags
    fetchTags({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/api/apps/email/tags")
          .then((response) => {
            commit("SET_TAGS", response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    // Fetch Email Meta
    fetchMeta({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/api/apps/email/meta")
          .then((response) => {
            commit("SET_META", response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    // Move mails to another folder
    moveMailsTo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/api/apps/email/move-mails", { emailIds: payload.emailIds, mailFolder: payload.to })
          .then((response) => {
            commit("MOVE_MAILS_TO", payload)
            commit("UPDATE_UNREAD_META_ON_MOVE", payload)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    // Update Mails label
    updateLabels({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/api/apps/email/update-labels", { emailIds: payload.mails, label: payload.label })
          .then((response) => {
            commit("UPDATE_LABELS", payload)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    setLabels({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/api/apps/email/set-labels", { mailId: payload.mailId, labels: payload.labels })
          .then((response) => {
            commit("SET_LABELS", payload)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    // Set mails flag unread to true
    setUnread({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/api/apps/email/mark-unread", { emailIds: payload.emailIds, unreadFlag: payload.unreadFlag })
          .then((response) => {
            commit("SET_UNREAD", payload)

            // Remove this if you are getting meta like us
            // Use your own method to update email meta if you are fetching email meta
            commit("UPDATE_UNREAD_META", payload)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    // Toggle isStarred flag in mail
    toggleIsMailStarred({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("/api/apps/email/set-starred", { mailId: payload.mailId, value: payload.value })
          .then((response) => {
            commit("TOGGLE_IS_MAIL_STARRED", payload)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
}
*/
