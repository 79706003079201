import state from './moduleNewsletterState.js'
import mutations from './moduleNewsletterMutations.js'
import actions from './moduleNewsletterActions.js'
import getters from './moduleNewsletterGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

