/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import _ from 'lodash';

export default {

  SET_AGE_GROUP_OPTIONS(state, ageGroups) {
    state.ageGroups = ageGroups
  },
  SET_REDIRECTION(state, data) {
    state.redirectURL = data;
  },
  RESET_REDIRECTION(state) {
    state.redirectURL = '';
  },
  SET_SELECTED_ROOMS(state, data) {
    if (state.selectedRooms.includes(data)) {
      let index = _.findIndex(state.selectedRooms, function (o) {
        return o == data;
      });
      state.selectedRooms.splice(index, 1);
    } else {
      state.selectedRooms.push(data);

    }
    console.log(state.selectedRooms, 'state array');
  }
}
