export default {

}
export function formattedUrl(url) {
  if (!url)
    return '';
  else {
    return url.includes('http') ? url : `https://${url}`;
  }
}
