/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {


  coreSkills: {},

  coreSkillsOptions: [],
  coreSkillFilter: {},

  rating: "1",
  coreSkillDevelopments: {},
  //fastCheckDevelopmentID: null,

  // Data Sidebar
  //addNewDataSidebar: false,
  //sidebarData: {},

  //currentResource:{}


}
