/*=========================================================================================
  File Name: modulePageManagementState.js
  Description: PageManagement Module State
  ----------------------------------------------------------------------------------------
  Item Name: Page Management
  Author: Sunidhi Amatya
  Author URL:
==========================================================================================*/

export default {
  pages: [],
  page: ''
}
