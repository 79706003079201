/*=========================================================================================
  File Name: moduleActivityState.js
  Description: activity state variable defined
  ----------------------------------------------------------------------------------------
  Item Name: Child
  Author: Samir Karmacharya
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  children: [],
  selectedChildId:'',
  parentChildren:[],
  child:''
}
