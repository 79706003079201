import jwt from "../../http/requests/auth/jwt/index.js";
import jwtCA from "../../http/requests/auth/jwt/centerAdmin.js";

export default {
  /* get room list*/
  async fetchCentreRoomList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCentreAdminRoomList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchTeacherByRoomIds({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminTeacherByRoomIds(data)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchChildrenByMultipleRooms({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchChildrenByMultipleRooms(data)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAdminActivity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchAdminActivity(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  learningStoryDataForDashboardAdmin({ commit }, param) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchLearningStoryCenterAdmin(param)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * Teacher
   **/
  getCenterAdminTeacherList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminTeacherList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addCenterAdminTeacher({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .addCenterAdminTeacher(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  importTeacherCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .importTeacherCsv(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getCenterAdminTeacherDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminTeacherDetails(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateCenterAdminTeacher({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .editCenterAdminTeacherDetails(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCenterAdminTeacher({ commit }, teacherId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .deleteCenterAdminTeacher(teacherId)
        .then(response => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  resetUserPassword({ commit }, userId) {
    return new Promise((resolve, reject) => {
      jwt
        .resetUserPassword(userId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**Children starts */
  getCenterAdminChildrenList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminChildrenList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addCenterAdminChild({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .addCenterAdminChild(data)
        .then(response => {
          const center = response.data.data;
          center.country = {};
          center.country.name = data.get("country");
          commit("ADD_CENTERS_OF_GROUP", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCenterAdminChildDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminChildDetails(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateCenterAdminChild({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .editCenterAdminChildDetails(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCenterAdminChild({ commit }, teacherId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .deleteCenterAdminChild(teacherId)
        .then(response => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  importChildrenCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .importChildrenCsv(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** Children Ends */

  /** Room starts */
  deleteCenterAdminRoom({ commit }, roomId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .deleteCenterAdminRoom(roomId)
        .then(response => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCenterAdminRoom({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .updateCenterAdminRoom(data)
        .then(response => {
          if (response.status === 200) {
            // commit('DELETE_CENTERS_OF_GROUP', teacherId)
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**Room Ends */

  /**
   * NQS
   */
  getNqsOutcomeList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getNqsOutcomeListGenerate(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getNqsChildrenHavingFastCheckScore({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getNqsChildrenCountHavingFastCheckScore(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /**
   * NQS Activities
   */
  getNqsChildStoryCount({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchNqsChildStoryCount(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   * NQS Fast Activities
   */
  getNqsFastCheck({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchNQSFastCheck(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   * NQS Teacher Development logs
   */
  getNqsTeacherDevelopmentLogs({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchNQSTeacherDevelopmentLogs(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   * NQS Teacher Self Assessments Count By rooms
   */
  getNqsSelfAssessmentsCountByCenterId({ commit }, centerId) {
    return new Promise((resolve, reject) => {
      jwtCA
        .fetchNQSSelfAssessmentCountByCenterId(centerId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   * NQS Activities
   */
  getNqsActivitiesList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getNqsActivitiesListGenerate(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /** about us */
  getCenterAdminAboutUs({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getAboutUsDetails(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  uploadImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .ImageUpload(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateAboutUs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .updateAboutUs(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createAboutUs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .saveAboutUs(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*
    Fetch Core skill Count
  */
  fetchCoreSkillCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCoreSkillCount(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*
    Fetch Ways of learning Count
  */
  fetchWaysOfLearningCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getWaysOfLearningCount(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*
    Fetch Stream Count
  */
  fetchStreamCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getStreamCount(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  /*
  Fetch Core skill compare for center Admin
*/
  fetchCenterAdminCoreSkillCompare({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminCoreSkillCompare(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*
   Fetch Ways of learning compare for center Admin
  */
  fetchCenterAdminWaysOfLearningCompare({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminWaysOfLearningCompare(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /*
Fetch Stream compare for director
*/
  fetchCenterAdminStreamCompare({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwtCA
        .getCenterAdminStreamCompare(data)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // Generate NQS Report
  generateNQS({ commit }, params) {
    return new Promise((resolve, reject) => {
      jwtCA
        .generateNqsReport(params)
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
