import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  fetchEYLFCategory({commit}, params) {
    return new Promise((resolve, reject) => {
      jwt
        .getEYLFCategory(params)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchEYLFCategoryWithFrameworkByCenterId({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt.getEYLFCategoryWithFrameworkByCenterId(centerId)
        .then(response => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  storeEYLFCategory({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addEYLFCategory(data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateEYLFCategory({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .editEYLFCategory(data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchEYLFCategoryByCountryId({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getEYLFCategoryByCountryId(id)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchEYLFCategoryByStateId({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getEYLFCategoryByStateId(id)
        .then((response) => {
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchEylfCategoryForTeacherByLearningCenterId({commit}, centerId) {
    return new Promise((resolve, reject) => {
      jwt.getEYLFCategoryForTeacher(centerId).then(response => {
        if (response.status === 200) {
          resolve(response);
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchEylfCategoryByMultipleLearningCenterId({commit}, centerIds) {
    return new Promise((resolve, reject) => {
      jwt.getEYLFCategoryByMultipleCenterId(centerIds).then(response => {
        if (response.status === 200) {
          resolve(response);
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  removeEylfCategoryById({commit}, id) {
    return new Promise((resolve, reject) => {
      jwt.deleteEylfCategoryById(id).then(response => {
        if (response.status === 200) {
          resolve(response);
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },

}
