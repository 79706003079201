/*=========================================================================================
  File Name: moduleActivityMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Activity Mutations
  Author: Samir Karmacharya
  Author URL:
==========================================================================================*/


export default {
  SET_ACTIVITIES(state, activities) {
    state.activities = activities
  },

  SET_ACTIVITY(state, activity) {
    state.activity = activity
  },
  RESET_ACTIVITY(state) {
    state.activity = '';
  },
  SET_FAVOURITE_ACTIVITY(state, activities) {
    state.favouriteActivities = activities
  },

  CHANGE_ACTIVITY_FAVOURITE_STATUS(state, activity) {
    const activityIndex = state.favouriteActivities.findIndex((u) => u._id == activity._id)

    if(activityIndex  == "-1") {
      state.favouriteActivities.unshift(activity)
    }else{
      state.favouriteActivities.splice(activityIndex, 1)
    }
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange
  }
}

