/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import axios from "../../http/axios/index.js"
import _ from 'lodash';

export default {

  getAllCountries({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .getAllCountries()
        .then((response) => {
          let countries = response.data.data;
          let stateDefault = {label: 'All', value: ''};

          _.forEach(countries, function (value) {
            value.label = value.name;
            value.value = value.name;
            value.id = value._id;
            _.forEach(value.states, function (stateValue) {
              stateValue.label = stateValue.name;
              stateValue.value = stateValue.name;
            })
            value.states.unshift(stateDefault);

          });

          let countryDefault = {label: 'All', value: '', states: [{label: 'All', value: ''}]};
          countries.unshift(countryDefault);
          commit('SET_COUNTRY_OPTIONS', countries)
          //this.countryOptions = countries;

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAllStates({commit}, filter) {

    return new Promise((resolve, reject) => {

      jwt
        .getAllStates(filter)
        .then((response) => {
          commit('SET_STATES', response.data.data[0].docs)

          let totalDoc = (response.data.data[0].pagination.length > 0) ? response.data.data[0].pagination[0].total : 0;

          commit('SET_TOTAL_STATES', totalDoc)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  getStateInfo({commit}, stateId) {
    return new Promise((resolve, reject) => {
      jwt
        .getStateInfo(stateId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addState({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addState(data)
        .then((response) => {
          console.log(response, "rest");
          commit('ADD_STATE', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteStateById({commit}, data) {

    return new Promise((resolve, reject) => {

      jwt
        .deleteStateByStateId(data)
        .then((response) => {
          commit('DELETE_STATE', data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
